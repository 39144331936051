<template>
    <q-tr
            :props="props"
            class="clickable"
    >
        <q-td
                key="min_volume"
                :props="props"
                style="border-bottom-width: 0 !important;"
        >
            <q-input
                    type="number"
                    :model-value="prevRowValue.count"
                    :disable="true"
            />
        </q-td>

        <q-td
                key="volume"
                :props="props"
                style="border-bottom-width: 0 !important;"
        >
            <q-input
                    type="number"
                    :disable="staticMode || disabled"
                    :model-value="props.row.count"
                    @update:model-value="handleChange(props, 'count', $event)"
            />
        </q-td>


        <q-td
                key="price"
                :props="props"
                style="border-bottom-width: 0 !important;"
        >
            <q-input
                    type="number"
                    :disable="staticMode || disabled"
                    :model-value="props.row.price"
                    @update:model-value="handleChange(props, 'price', $event)"
            />
        </q-td>

        <q-td
                key="actions"
                :props="props"
                style="border-bottom-width: 0 !important;"
                auto-width
        >
            <q-btn
                    v-if="!staticMode"
                    color="negative"
                    icon="close"
                    flat
                    :disable="disabledDelete || disabled"
                    @click="handleDelete"
            />
        </q-td>
    </q-tr>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

export default {
    name: 'RateModifierTieredTableRow',
    emits: ['change', 'delete'],
    props: {
        disabled: {
            type: Boolean,
            default () {
                return false
            }
        },
        staticMode: {
            type: Boolean,
            default () {
                return false
            }
        },
        disabledDelete: {
            type: Boolean,
            default () {
                return false
            }
        },
        prevRow: {
            type: [Object, null],
            default () {
                return null
            }
        },
        props: {
            type: Object,
            default () {
                return null
            }
        },
        nextRow: {
            type: [Object, null],
            default () {
                return null
            }
        }
    },
    computed: {
        prevRowValue () {
            return this.prevRow || { count: 0, price: 0 }
        }
    },
    methods: {
        ...mapMutations([
            'addErrorNotification'
        ]),
        handleDelete () {
            this.$emit('delete', this.props.rowIndex)
        },
        handleChange (props, key, e) {
            if (key !== 'price' && key !== 'code') {
                if (this.nextRow && Number(e) >= Number(this.nextRow[key])) {
                    this.addErrorNotification('You can\'t write value bigger than next one.')
                    return this.$emit('change', { ...props, row: { ...props.row } })
                }

                if (this.prevRow && Number(e) <= Number(this.prevRow[key])) {
                    this.addErrorNotification('You can\'t write value smaller than previous one.')
                    return this.$emit('change', { ...props, row: { ...props.row } })
                }
            }

            return this.$emit('change', { ...props, row: { ...props.row, [key]: Number(e) } })
        }
    }
}
</script>
