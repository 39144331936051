<template>
  <div class="">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :hint="$t('Add a code of an external document.')"
            :disable="disabled"
            :label="$t('Code (optional)')"
            @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :disable="disabled"
            :label="$t('Code')"
            @update:model-value="handleInput"
        />
      </div>


      <div class="q-py-sm q-px-xs">
        <q-input
            :model-value="grace"
            type="number"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :rules="[val => /^[0-9]\d*$/.test(val) || 'Please enter a positive integer.']"
            :disable="disabled"
            :label="$t('Grace Period (in days)')"
            @update:model-value="handleGraceInput"
        />
      </div>

        <div class="col-sm-7">
            <form-builder :schema="schema"/>
        </div>

    </div>

    <q-table
        v-if="model.rates.length > 0"
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="model.rates"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: model.rates.length, rowsNumber: model.rates.length }"
        :rows-per-page-options="[]"
        :hide-bottom="true"
        virtual-scroll
        binary-state-sort
        flat
        dense
    >
      <template v-slot:body="props">
        <rate-modifier-table-row
            :prev-row="getPrevRow(props.rowIndex)"
            :next-row="getNextRow(props.rowIndex)"
            :props="props"
            :disabled="disabled"
            :disabled-delete="model.rates.length <= 1"
            :static-mode="staticMode"
            @delete="handleDelete"
            @change="handleChange"
        />
      </template>
    </q-table>

    <div v-if="!staticMode" class="text-left q-pb-md q-pt-sm q-px-xs">
      <div
          class="clickable rounded q-px-sm q-py-md text-caption"
          :class="$q.dark.isActive ? 'bg-dark' : 'bg-grey-3 text-grey-7'"
          :disable="disabled"
          @click="handleAdd"
      >
        <span class="border-bottom" style="border-bottom-color: #757575 !important;">
          <q-icon name="add" />

          <span>
            {{ $t('Add a Row') }}...
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import RateModifierTableRow from './RateModifierTableRow.vue'

export default {
  name: 'RateModifierItemVolume',
  emits: ['change', 'delete'],
  components: {
    RateModifierTableRow
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    code: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          grace: 0,
          rates: [],
          sections: {
            included: [],
            excluded: []
          }
        }
      }
    }
  },
  data () {
    return {
      grace: this.model.grace,
      columns: [
        {
          label: this.$t('Min Volume'),
          name: 'min_volume',
          align: 'center'
        },
        {
          label: this.$t('Max Volume'),
          name: 'volume',
          align: 'center'
        },
        {
          label: this.$t('Min Weight'),
          name: 'min_weight',
          align: 'center'
        },
        {
          label: this.$t('Max Weight'),
          name: 'weight',
          align: 'center'
        },
        {
          label: this.$t('Rate'),
          name: 'price',
          align: 'center'
        },
        {
          label: this.$t('Code (optional)'),
          name: 'code',
          align: 'center',
          classes: this.code ? '' : 'd-none',
          headerClasses: this.code ? '' : 'd-none'
        },
        {
          label: '',
          name: 'actions',
          align: 'right',
          classes: this.staticMode
              ? 'd-none'
              : ''
        }
      ]
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'tag',
                label: this.$t('Include sections'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.model?.sections?.included || [],
                disabled: this.disabled,
                hint: 'Input (scan/write) included section barcode and press the Enter key to save it.',
                onSubmit: (value) => {
                  let label = 'included'
                  let oppositeLabel = 'excluded'

                  this.addToSection(value, label, oppositeLabel)
                },
                onRemove: (value) => {
                  let label = 'included'

                  this.removeFromSection(value, label)
                }
              },
              {
                type: 'tag',
                value: this.model?.sections?.excluded || [],
                wrapperStyleClasses: 'col-12 q-pa-xs',
                disabled: this.disabled,
                label: this.$t('Excluded section'),
                hint: 'Input (scan/write) excluded section barcode and press the Enter key to save it.',
                onSubmit: (value) => {
                  let label = 'excluded'
                  let oppositeLabel = 'included'

                  this.addToSection(value, label, oppositeLabel)
                },
                onRemove: (value) => {
                  let label = 'excluded'

                  this.removeFromSection(value, label)
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    handleRemove () {
      this.$emit('delete', this.model)
    },
    emitChange (model) {
      this.$emit('change', model)
    },
    getPrevRow (index) {
      const rates = (this.model.rates || [])
      return rates[index - 1] || null
    },
    getNextRow (index) {
      const rates = (this.model.rates || [])
      return rates[index + 1] || null
    },
    handleInput (code) {
      this.emitChange({
        ...this.model,
        code
      })
    },
    handleGraceInput (grace) {
      this.grace = grace
      if(/^[1-9]\d*$/.test(grace)) {

        if( this.model.grace !== this.grace) {
          this.emitChange({
            ...this.model,
            grace
          })
        }
      }

    },
    handleDelete (rowIndex) {
      const update = {
        ...this.model,
        rates: this.model.rates.filter((x, i) => i !== rowIndex)
      }

      this.emitChange(update)
    },
    handleAdd () {
      if (this.disabled) {
        return
      }

      const lastRate = this.model.rates[this.model.rates.length - 1]
      const newRow = lastRate
          ? { price: 0, volume: Number(lastRate.volume) + 1, weight: Number(lastRate.weight) + 1 }
          : { price: 0, volume: 0, weight: 0 }

      const update = {
        ...this.model,
        rates: [
          ...this.model.rates,
          newRow
        ]
      }

      this.emitChange(update)
    },
    handleChange ({ rowIndex, row }) {
      const update = {
        ...this.model,
        rates: [
          ...this.model.rates.slice(0, rowIndex),
          row,
          ...this.model.rates.slice(rowIndex + 1)
        ]
      }

      this.emitChange(update)
    },
    addToSection (value, label, oppositeLabel) {
      if (!value) {
        return
      }

      if (!this.model.sections || this.model.sections.length === 0) {
        this.model.sections = {
          [label]: [],
          [oppositeLabel]: []
        }
      }

      let isExist = false
      if (this.model.sections[label]) {
        this.model.sections[label].filter(val => {
          if (val === value) {
            isExist = true
          }
        })
      }

      if (!isExist) {
        this.model.sections[label].push(value)
        this.model.sections[oppositeLabel] = []

        this.$emit('change', this.model)
      }
    },
    removeFromSection (value, label) {
      if (!value) {
        return
      }

      let isExist = false
      let key = null

      this.model.sections[label].forEach((val, index) => {
        if (val === value) {
          isExist = true
          key = index
        }
      })

      if (isExist) {
        this.model.sections[label].splice(key, 1)

        this.$emit('change', this.model)
      }
    }
  }
}
</script>
