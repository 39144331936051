<template>
  <q-tr
    :props="props"
    class="clickable"
  >
    <q-td
        key="code"
        :props="props"
        style="border-bottom-width: 0 !important;"
    >
      <form-builder ref="formBuilder" :schema="schema" />
<!--      <q-input-->
<!--          type="text"-->
<!--          :disable="staticMode || disabled"-->
<!--          :model-value="props.row.code"-->
<!--          @update:model-value="handleChange(props, 'code', $event)"-->
<!--      />-->
    </q-td>

    <q-td
      key="min"
      :props="props"
      style="border-bottom-width: 0 !important;"
    >
      <q-input
        type="number"
        :model-value="prevRowValue.max|| 0"
        :disable="true"
      />
    </q-td>

    <q-td
      key="max"
      :props="props"
      style="border-bottom-width: 0 !important;"
    >
      <q-input
        type="number"
        :disable="!props.row.code || staticMode || disabled"
        :model-value="props.row.max"
        @update:model-value="handleChange(props, 'max', $event)"
      />
      <q-tooltip v-if="!props.row.code">
          {{'Please select Supply'}}
      </q-tooltip>
    </q-td>

    <q-td
      key="rate"
      :props="props"
      style="border-bottom-width: 0 !important;"
    >
      <q-input
        type="number"
        :disable="!props.row.code || staticMode || disabled"
        :model-value="props.row.rate"
        @update:model-value="handleChange(props, 'rate', $event)"
      />
      <q-tooltip v-if="!props.row.code">
          {{'Please select Supply'}}
      </q-tooltip>
    </q-td>

    <q-td
      key="actions"
      :props="props"
      style="border-bottom-width: 0 !important;"
      auto-width
    >
      <q-btn
        v-if="ifAddButton && !staticMode"
        color="positive"
        icon="add"
        flat
        :disable="!props.row.code"
        @click="handleAdd"
      />

      <q-btn
        v-if="!staticMode"
        color="negative"
        icon="close"
        flat
        :disable="disabledDelete || disabled"
        @click="handleDelete"
      />
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'
import {buildQuery} from "@/apps/app/utils/query-utils";

export default {
  name: 'RateModifierServicesTableRow',
  emits: ['change', 'delete', 'add'],
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabledDelete: {
      type: Boolean,
      default () {
        return false
      }
    },
    prevRow: {
      type: [Object, null],
      default () {
        return null
      }
    },
    props: {
      type: Object,
      default () {
        return null
      }
    },
    supplies: {
        type: [Object, null],
        default () {
            return null
        }
    },
    usedSupplies: {
      type:  Array,
      default () {
          return []
      }
    },
    nextRow: {
      type: [Object, null],
      default () {
        return null
      }
    }
  },
  computed: {
    supply () {
        const supply = this.supplies.filter(e=>e.id === this.props.row.code)

        return supply[0]
    },
    ifAddButton () {
      if (this.nextRow && this.nextRow.code && this.props.row.code) {
        if(this.nextRow.code === this.props.row.code) {
            return false
        } else {
            return true
        }
      } else if (this.nextRow && !this.nextRow.code && !this.props.row.code) {
          return false
      } else {
          return true
      }
    },
    prevRowValue () {
      if (this.prevRow && this.prevRow.code && this.props.row.code) {
        if(this.prevRow.code=== this.props.row.code) {
            return this.prevRow
        } else {
            return { weight: 0, volume: 0 ,max: 0}
        }
      } else if(this.prevRow && this.prevRow.code && !this.props.row.code) {
          return { weight: 0, volume: 0 }
      } else if (this.prevRow && !this.prevRow.code && this.props.row.code) {
          return { weight: 0, volume: 0 }
      }
      return this.prevRow || { weight: 0, volume: 0 }
    },
      schema () {

          return {
              groups: [
                  {
                      id: 'form',
                      styleClasses: 'row',
                      fields: [
                          {
                              ref: 'supplies',
                              type: 'multiselect',
                              label: this.$t('Supplies'),
                              field: 'supplies',
                              value: this.supply,
                              required: true,
                              disabled: !this.ifAddButton,
                              wrapperStyleClasses: 'col-12',
                              customLabel(row) {
                                  if (row && typeof row === 'object') {
                                      return `${row.name} (${row.id})`
                                  }

                                  return row
                              },
                              onScroll: (search) => {

                                  let query = {
                                    search
                                  }

                                  query['order-by'] = [
                                      {type: 'field', field: 'id', direction: 'desc'}
                                  ]
                                  query.filter = [{type: 'eq', field: 'state', value: 'active'}]

                                  if ( this.usedSupplies && this.usedSupplies.length > 0) {
                                      query.filter.push({type: 'notin', field: 'id', values: this.usedSupplies})
                                  }

                                  return this.$service.supply.getAll(query)
                              },
                              onChange: (supply) => {
                                  this.usedSupplies.push(supply.id)
                                  this.handleChange(this.props, 'code', supply.id)
                              }
                          },
                      ]
                  }
              ]
          }
      }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleAdd () {
      this.$emit('add', this.props.row , this.props.rowIndex)
    },
    handleDelete () {
      this.$emit('delete', this.props.rowIndex)
    },
    handleChange (props, key, e) {
      if (key !== 'rate' && key !== 'code' && key !== 'supply') {
        e = Number(e)
      }

      return this.$emit('change', { ...props, row: { ...props.row, [key]: e } })
    }
  }
}
</script>
