<template>
  <div>
    <div
        v-if="staticMode"
        class="row items-center q-pa-md"
        @click="handleExpand"
    >
      <div class="text-subtitle1 text-weight-bold">
        {{ model.name }}
      </div>

      <q-space/>

      <q-icon :name="isOpen ? 'expand_less' : 'expand_more'" size="1rem"/>
    </div>

    <q-slide-transition>
      <div v-if="isExpanded">
        <div class="row items-center q-px-md q-py-sm">
          <div class="text-h6 text-capitalize">
            {{ $t(title) }}
          </div>

          <q-space/>

          <div>
            <q-btn
                v-if="!staticMode"
                color="orange-6"
                :label="$t('Description for transactions') + '*'"
                class="q-mr-sm"
                no-caps
                @click="isOpenCommentForm = !isOpenCommentForm"
            />

            <q-btn
                v-if="hasLabelRateBtn && !staticMode"
                color="light-blue-6"
                icon="add"
                :label="$t('Label rate')"
                no-caps
                :disable="model.settings && model.settings.surcharges && model.settings.surcharges.length > 0"
                @click="handleAddLabelRate"
            />

            <q-btn
                v-if="!staticMode"
                class="q-ml-sm"
                color="negative"
                icon="delete"
                no-caps
                :disable="disabled"
                @click="handleDelete"
            />
          </div>
        </div>

        <form-builder :schema="schema" class="q-px-md q-mb-sm"/>

        <q-slide-transition>
          <form-builder
              v-if="isOpenCommentForm"
              :schema="commentSchema"
              class="q-px-md q-mb-sm"
          />
        </q-slide-transition>

        <component
            :is="component"
            v-if="!isCustom"
            :model="model.settings"
            :rate="model"
            :static-mode="staticMode"
            :keywords="keywords"
            :disabled="disabled"
            class="border-top"
            @change="handleChange"
            @delete="handleDelete"
            @rateChange="handleModelChange"
            portal
        />

        <rate-modifier-custom
            v-else
            :model="model"
            :static-mode="staticMode"
            :disabled="disabled"
            class="border-top"
            @change="handleModelChange"
            portal
        />
      </div>
    </q-slide-transition>
  </div>
</template>

<script>
// Components
import RateModifierTable from './RateModifierTable.vue'
import RateModifierQuantityTable from './RateModifierQuantityTable.vue'
import RateModifierAcceptance from './RateModifierAcceptance'
import RateModifierShipment from './RateModifierShipment'
import RateModifierReturns from './RateModifierReturns'
import RateModifierInsurance from './RateModifierInsurance'
import RateModifierDelivery from './RateModifierDelivery'
import RateModifierCustom from './RateModifierCustom.vue'
import RateModifierServices from './RateModifierServices.vue'
import RateModifierStorage from './RateModifierStorage'
import RateModifierItemVolume from './RateModifierItemVolume'
import RateModifierPalletWarehousing from './RateModifierPalletWarehousing'
import RateModifierTiered from './RateModifierTiered'
import RateModifierTieredOrder from './RateModifierTieredOrder'
import RateModifierRetail from './RateModifierRetail.vue'
import RateModifierOrderFeeRetail from './RateModifierOrderFeeRetail.vue'

// Configs
import { rmCodeService } from '../../services/requests/rm-code.service'

export default {
  name: 'RateModifier',
  emits: ['change', 'delete'],
  components: {
    RateModifierTable,
    RateModifierAcceptance,
    RateModifierShipment,
    RateModifierReturns,
    RateModifierInsurance,
    RateModifierDelivery,
    RateModifierCustom,
    RateModifierServices,
    RateModifierStorage,
    RateModifierItemVolume,
    RateModifierPalletWarehousing,
    RateModifierTiered,
    RateModifierTieredOrder,
    RateModifierRetail,
    RateModifierOrderFeeRetail,
    RateModifierQuantityTable
  },
  props: {
    name: {
      type: String,
      default () {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    keywords: {
      type: Array,
      default () {
        return rmCodeService.keywords
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    isCustom: {
      type: Boolean,
      default () {
        return false
      }
    },
    noActions: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isOpen: false,
      isOpenCommentForm: false,
      types: [
        { id: 'fixed', name: this.$t('Fixed') },
        { id: 'percent', name: this.$t('Percent') },
        { id: 'managed', name: this.$t('Managed') }
      ],
      components: {
        'Orderadmin\\Products\\Billing\\ShippingAdapter': 'rate-modifier-shipment',
        'Orderadmin\\Storage\\Billing\\AcceptanceAdapter': 'rate-modifier-acceptance',
        'Orderadmin\\DeliveryServices\\Billing\\DeliveryAdapter': 'rate-modifier-delivery',
        'Orderadmin\\Products\\Billing\\ReturnAdapter': 'rate-modifier-returns',
        'Orderadmin\\Storage\\Billing\\Warehousing\\VolumeAdapter': 'rate-modifier-storage',
        'Orderadmin\\Storage\\Billing\\InsuranceAdapter': 'rate-modifier-insurance',
        'Orderadmin\\Storage\\Billing\\ServiceAdapter': 'rate-modifier-services',
        'Orderadmin\\Products\\Billing\\ServiceRecurrentAdapter': 'rate-modifier-services',
        'Orderadmin\\Storage\\Billing\\Warehousing\\PlaceVolumeAdapter': 'rate-modifier-storage',
        'Orderadmin\\Storage\\Billing\\Warehousing\\ItemVolumeAdapter': 'rate-modifier-item-volume',
        'Orderadmin\\Storage\\Billing\\Warehousing\\PalletBillingAdapter': 'rate-modifier-pallet-warehousing',
        'Orderadmin\\Products\\Billing\\TieredAdapter': 'rate-modifier-tiered',
        'Orderadmin\\Products\\Billing\\TieredOrderAdapter': 'rate-modifier-tiered-order',
        'Orderadmin\\Products\\Billing\\RetailAdapter': 'rate-modifier-retail',
        'Orderadmin\\Products\\Billing\\OrderFeeRetailAdapter': 'rate-modifier-order-fee-retail',
        'Orderadmin\\Integrations\\Chestnyznak\\Billing\\DigitalMarkAdapter': 'rate-modifier-quantity-table',
        default: 'rate-modifier-table'
      }
    }
  },
  computed: {
    hasLabelRateBtn () {
      return this.model.code === 'acceptance.taxation'
    },
    title () {
      if (this.model.settings && this.model.settings.conditions) {
        const filter = this.model.settings.conditions.find(x => x.field === 'type')
        if (filter) {

          return filter.value
        }
      }

      return this.name
    },
    component () {
      return this.components[this.model.object] || this.components.default
    },
    isExpanded () {
      return this.staticMode
          ? this.isOpen
          : true
    },
    commentSchema () {
      return {
        groups: [
          {
            fields: [
              {
                if: !this.staticMode,
                type: 'editor',
                field: 'comment',
                value: this.model.comment,
                hint: this.$t('Create a template for the comments for the fee translations. Use the «insert» words which are under the field. The words will be changed on the exact numbers/names of chosen entities for each transaction.'),
                disabled: this.disabled,
                required: true,
                label: this.$t('Comment'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                toolbarPosition: 'bottom',
                toolbar: [
                  {
                    id: 1,
                    type: 'chips',
                    label: this.$t('Insert'),
                    options: this.keywords,
                    onClick: (option, editor) => {
                      this.handleInsert(option, editor)
                    }
                  }
                ],
                onChange: (comment) => {
                  this.$emit('change', { ...this.model, comment })
                }
              }
            ]
          }
        ]
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                if: !this.staticMode,
                type: 'input',
                inputType: 'text',
                field: 'name',
                value: this.model.name,
                required: true,
                label: this.$t('Charge name'),
                wrapperStyleClasses: 'col-12 col-md q-pa-xs',
                hint: this.$t('Give it a name to identify it later.'),
                disabled: this.staticMode || this.disabled,
                onChange: (name) => {
                  this.$emit('change', { ...this.model, name })
                }
              },
              {
                if: !this.staticMode,
                type: 'portal-target',
                portal: 'rate-modifier-form',
                wrapperStyleClasses: 'col-12 col-md q-pa-xs',
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    model: {
      handler (value) {
        this.$emit('change', value)
      },
      deep: true
    }
  },
  beforeMount () {
    if (this.model.code === 'acceptance.taxation') {
      if (Array.isArray(this.model.settings.rates) && !this.model.settings.conditions) {
        this.$emit('change', {
          ...this.model,
          settings: {
            ...this.model.settings,
            ...(
                this.model.settings.rates[0] ||
                {
                  conditions: [
                    { type: 'eq', field: 'type', value: 'simple' }
                  ],
                  code: null,
                  name: '',
                  comment: '',
                  surcharges: []
                }
            )
          }
        })
      }
    }
  },
  methods: {
    handleAddLabelRate () {
      if (this.model.settings && this.model.settings.surcharges && this.model.settings.surcharges.length > 0) {
        return
      }

      const settings = { ...this.model.settings }
      settings.surcharges = [...(settings.surcharges || []), { code: null, type: 'barcode', rate: 0 }]
      this.handleChange(settings)
    },
    handleInsert (option, editor) {
      const data = {
        content: option.name,
        props: {
          style: 'background: orange;',
          'data-id': option.id
        }
      }

      editor.plugins.span.insert(data)
    },
    handleExpand () {
      this.isOpen = !this.isOpen
    },
    handleChange (settings) {
      this.$emit('change', { ...this.model, settings })
    },
    handleModelChange (model) {
      this.$emit('change', model)
    },
    handleDelete () {
      this.$emit('delete', this.model.id)
    }
  }
}
</script>
