<template>
  <form-builder :schema="schema" />
</template>

<script>
export default {
  name: 'RateModifierInsurance',
  emits: ['change'],
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          rates: null
        }
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'number',
                field: 'rates',
                value: this.model.rates,
                disabled: this.staticMode || this.disabled,
                label: this.$t('Rate'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (rates) => {
                  this.$emit('change', { ...this.model, rates })
                }
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
