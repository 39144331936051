<template>
  <div>
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
          :model-value="model.code"
          type="text"
          color="white"
          standout="bg-teal text-white"
          label-color="light"
          :hint="$t('Add a code of an external document.')"
          :disable="disabled"
          :label="$t('Code (optional)')"
          @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
          :model-value="model.code"
          type="text"
          color="white"
          standout="bg-teal text-white"
          label-color="light"
          :disable="disabled"
          :label="$t('Code')"
          @update:model-value="handleInput"
        />
      </div>
        <div class="q-py-sm q-px-xs row">
            <div class="col">
                <form-builder :schema="mainRateSchema" />
            </div>
        </div>
    </div>

    <q-table
      v-if="model.rates.length > 0"
      row-key="id"
      :rows-per-page-label="$t('Rows per page')"
      :rows="model.rates"
      :columns="columns"
      :pagination="{ page: 1, rowsPerPage: model.rates.length, rowsNumber: model.rates.length }"
      :rows-per-page-options="[]"
      :hide-bottom="true"
      virtual-scroll
      binary-state-sort
      flat
      dense
    >
      <template v-slot:body="props">
        <q-tr
          :props="props"
          class="clickable"
        >
          <q-td
            key="deliveryService"
            :props="props"
            style="border-bottom-width: 0 !important;"
          >
            <multiselect
              :schema="{
                ...schema,
                value: props.row.deliveryService,
                onChange: (value) => {
                  handleChange(props, 'deliveryService', value && value.id)

                  if (value) {
                    deliveryServices[value.id] = value
                  }
                }
              }"
            />
          </q-td>

          <q-td
            key="rate"
            :props="props"
            style="border-bottom-width: 0 !important;"
          >
            <multiselect
              :schema="{
                ...rateSchema,
                value: props.row.rate,
                onChange: (value) => {
                  handleChange(props, 'rate', value && value.id)

                  if (value) {
                    handleChange(props, 'deliveryService', value._embedded.deliveryService.id)
                    deliveryServices[value._embedded.deliveryService.id] = value._embedded.deliveryService
                    rates[value.id] = value
                  }
                }
              }"
            />
          </q-td>

          <q-td
            key="price"
            :props="props"
            style="border-bottom-width: 0 !important;"
          >
            <q-input
              type="number"
              :model-value="props.row.price"
              :disable="staticMode || disabled"
              @update:model-value="handleChange(props, 'price', $event)"
            />
          </q-td>

          <q-td
            key="actions"
            :props="props"
            style="border-bottom-width: 0 !important;"
          >
            <q-btn
              v-if="!staticMode"
              color="negative"
              icon="close"
              flat
              :disable="disabled"
              @click="handleDelete(props.rowIndex)"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <div v-if="!staticMode" class="text-left q-pb-md q-pt-sm q-px-xs">
      <div
        class="clickable rounded q-px-sm q-py-md text-caption"
        :class="$q.dark.isActive ? 'bg-dark' : 'bg-grey-3 text-grey-7'"
        :disable="disabled"
        @click="handleAdd"
      >
        <span class="border-bottom" style="border-bottom-color: #757575 !important;">
          <q-icon name="add" />

          <span>
            {{ $t('Add a Row') }}...
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import Multiselect from '../../../../packages/form-builder/components/Multiselect.vue'

export default {
  name: 'RateModifierDelivery',
  emits: ['change', 'delete','rateChange'],
  components: {
    Multiselect
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    code: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          rates: []
        }
      }
    },
    rate: {
      type: Object,
      default () {
        return {
          event: null,
          code: null
        }
      }
    }
  },
  data () {
    return {
      deliveryServices: {},
      rates: {},
      columns: [
        {
          label: this.$t('Shipping carrier'),
          name: 'deliveryService',
          align: 'center'
        },
        {
          label: this.$t('Rate'),
          name: 'rate',
          align: 'center'
        },
        {
          label: `${this.$t('Value')} (%)`,
          name: 'price',
          align: 'center'
        },
        {
          label: '',
          name: 'actions',
          align: 'center',
          classes: this.staticMode
            ? 'd-none'
            : ''
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    mainRateSchema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'event',
                value: this.rate.code,
                disabled: this.staticMode || this.disabled,
                label: this.$t('Code'),
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (rate) => {
                  this.$emit('rateChange', { ...this.rate, rate })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'event',
                value: this.rate.event,
                disabled: this.staticMode || this.disabled,
                label: this.$t('Event'),
                wrapperStyleClasses: 'col-6 q-pa-xs',
                onChange: (event) => {
                  this.$emit('rateChange', { ...this.rate, event })
                }
              }
            ]
          }
        ]
      }
    },
    schema () {
      return {
        type: 'multiselect',
        label: this.$t('Shipping carrier'),
        hasResetBtn: true,
        field: 'deliveryService',
        imagePreview: (row) => {
          if (!row || !row.logo) {
            return ''
          }

          return `${this.appOptions.defaultServer}${row.logo}`
        },
        customLabel: (row) => {
          if (row) {
            if (typeof row === 'object') {
              return `${row.name} (${row.id})`
            }

            if (this.deliveryServices[row]) {
              return `${this.deliveryServices[row].name} (${this.deliveryServices[row].id})`
            }
          }

          return row
        },
        onScroll: (search, page) => {
          const query = {
            per_page: 25,
            page,
            search,
            filter: [
              { type: 'eq', field: 'state', value: 'active' }
            ]
          }

          return this.$service.deliveryService.getAll(query)
        }
      }
    },
    rateSchema () {
      return {
        type: 'multiselect',
        label: this.$t('Shipping rate'),
        hasResetBtn: true,
        field: 'rate',
        imagePreview: (row) => {
          if (!row || !row._embedded || !row._embedded.deliveryService || !row._embedded.deliveryService.logo) {
            return ''
          }

          return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
        },
        customLabel: (row) => {
          if (row) {
            if (typeof row === 'object') {
              return `${row.name} (${row.id})`
            }

            if (this.rates[row]) {
              return `${this.rates[row].name} (${this.rates[row].id})`
            }
          }

          return row
        },
        onScroll: (search, page) => {
          const query = {
            per_page: 25,
            page,
            search,
            filter: [
              { type: 'eq', field: 'state', value: 'active' }
            ]
          }

          return this.$service.deliveryServiceRate.getAll(query)
        }
      }
    }
  },
  mounted () {
    const deliveryServices = (this.model.rates || []).map(x => x.deliveryService).filter(x => !!x)
    
    if (deliveryServices.length > 0) {
      const query = {
        page: 1,
        per_page: 250,
        filter: [
          { type: 'in', field: 'id', values: deliveryServices }
        ]
      }

      this.$service.deliveryService.getAll(query)
        .then(({ items }) => {
          this.deliveryServices = items.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        })
    }

    const rates = (this.model.rates || []).map(x => x.rate).filter(x => !!x)
    
    if (rates.length > 0) {
      const query = {
        page: 1,
        per_page: 250,
        filter: [
          { type: 'in', field: 'id', values: rates }
        ]
      }

      this.$service.deliveryServiceRate.getAll(query)
        .then(({ items }) => {
          this.rates = items.reduce((acc, item) => {
            acc[item.id] = item
            return acc
          }, {})
        })
    }
  },
  methods: {
    emitChange (model) {
      this.$emit('change', model)
    },
    handleInput (code) {
      this.emitChange({
        ...this.model,
        code
      })
    },
    handleDelete (rowIndex) {
      const update = {
        ...this.model,
        rates: this.model.rates.filter((x, i) => i !== rowIndex)
      }

      this.emitChange(update)
    },
    handleAdd () {
      if (this.disabled) {
        return
      }

      const update = {
        ...this.model,
        rates: [
          ...this.model.rates,
          { price: null, rate: null, deliveryService: null }
        ]
      }

      this.emitChange(update)
    },
    handleChange (tableRow, key, value) {
      tableRow.row[key] = value

      const update = {
        ...this.model,
        rates: [
          ...this.model.rates.slice(0, tableRow.rowIndex),
          { ...this.model.rates[tableRow.rowIndex], [key]: value },
          ...this.model.rates.slice(tableRow.rowIndex + 1)
        ]
      }

      this.emitChange(update)
    }
  }
}
</script>
