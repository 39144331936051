<template>
  <div class="q-px-md q-pb-md">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
          :model-value="model.code"
          type="text"
          color="white"
          standout="bg-teal text-white"
          label-color="light"
          :hint="$t('Add a code of an external document.')"
          :disable="disabled"
          :label="$t('Code (optional)')"
          @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
          :model-value="model.code"
          type="text"
          color="white"
          standout="bg-teal text-white"
          label-color="light"
          :disable="disabled"
          :label="$t('Code')"
          @update:model-value="handleInput"
        />
      </div>
    </div>

    <h5 class="text-center q-my-md">
      {{ $t('Full returned order') }}
    </h5>
    
    <div class="row">
      <div class="col-4">
        <form-builder :schema="schema" />
      </div>

      <div class="col q-pa-md text-subtitle1">
        <span>
          {{ $t('Return processing for each order charges ') }}
        </span>

        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
          style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.return.order }}
        </span>
        .

        <div>
          {{ $t('AND') }}
        </div>

        <span>
          {{ $t('Return processing for each item in the order charges ') }}
        </span>

        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
          style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.return.item }}
        </span>
        .
      </div>
    </div>

    <h5 class="text-center q-my-md">
      {{ $t('Partly returned order') }}
    </h5>

    <div class="row">
      <div class="col-4">
        <form-builder :schema="partlyReturnSchema" />
      </div>

      <div class="col q-pa-md text-subtitle1">
        <span>
          {{ $t('Return processing for each order charges ') }}
        </span>

        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
          style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.partly_return.order }}
        </span>
        .

        <div>
          {{ $t('AND') }}
        </div>

        <span>
          {{ $t('Return processing for each item in the order charges ') }}
        </span>

        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
          style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.partly_return.item }}
        </span>
        .
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RateModifierReturns',
  emits: ['change'],
  props: {
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          rates: {
            partly_return: {},
            return: {}
          }
        }
      }
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            fields: this.createSchema().map(field => {
              return {
                ...field,
                value: this.model.rates.return[field.field],
                disabled: this.staticMode || this.disabled,
                onChange: (value) => {
                  const update = {
                    ...this.model,
                    rates: {
                      ...this.model.rates,
                      return: {
                        ...this.model.rates.return,
                        [field.field]: value
                      }
                    }
                  }

                  this.$emit('change', update)
                }
              }
            })
          }
        ]
      }
    },
    partlyReturnSchema () {
      return {
        groups: [
          {
            fields: this.createSchema().map(field => {
              return {
                ...field,
                value: this.model.rates.partly_return[field.field],
                disabled: this.staticMode || this.disabled,
                onChange: (value) => {
                  const update = {
                    ...this.model,
                    rates: {
                      ...this.model.rates,
                      partly_return: {
                        ...this.model.rates.partly_return,
                        [field.field]: value
                      }
                    }
                  }

                  this.$emit('change', update)
                }
              }
            })
          }
        ]
      }
    }
  },
  methods: {
    createSchema () {
      return [
        {
          type: 'input',
          inputType: 'text',
          field: 'order',
          label: this.$t('Order'),
          wrapperStyleClasses: 'q-py-xs'
        },
        {
          type: 'input',
          inputType: 'text',
          field: 'item',
          label: this.$t('Item'),
          wrapperStyleClasses: 'q-py-xs'
        },
        {
          type: 'input',
          inputType: 'number',
          field: 'code',
          label: this.$t('Code'),
          wrapperStyleClasses: 'q-py-xs'
        }
      ]
    }
  }
}
</script>
