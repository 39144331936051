<template>
  <div v-if="legalEntity">
    <div class="row q-py-md">
      <div class="multisteps-form full-width">
        <div class="multisteps-form__progress">
          <button
            v-for="btn in tabButtons"
            :key="btn.id"
            :class="btn.className"
            @click="btn.onClick"
          >
            {{ $t(btn.title) }}
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="activeTab === 'basic'"
      class="row"
    >
      <form-builder :schema="schema" />
    </div>

    <div
      v-if="activeTab === 'additional'"
      class="row"
    >
      <div v-if="eavs.length <= 0" class="text-subtitle1">
          {{ $t('No EAV Atributes') }}
      </div>

      <form-builder v-else :schema="schemaEAV"  />

    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'LegalEntityForm',
  props: {
    type: {
      type: String,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      tax: 0,
      eavs: [],
      statuses: [
        { id: 'active', title: this.$t('Active') },
        { id: 'inactive', title: this.$t('Inactive') },
        { id: 'deleted', title: this.$t('Deleted') }
      ],
      activeTab: 'basic',
      isDisableType: false
    }
  },
  computed: {
    ...mapGetters([
      'legalEntity',
      'isSupervisior',
      'isAdministrator'
    ]),
    tabButtons () {
      const btnClass = 'multisteps-form__progress-btn'
      const tabs = [
        { id: 'basic', title: 'Basic Info' },
        { id: 'additional', title: 'Additional Info' }
      ]

      return tabs.map(tab => {
        return {
          id: tab.id,
          className: this.activeTab === tab.id ? btnClass + ' step-active' : btnClass,
          onClick: () => {
            this.activeTab = tab.id
          },
          title: this.$t(tab.title)
        }
      })
    },
    additinalSchema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.legalEntity.eav['legal-entity-bank-name'],
                label: this.$t('Bank name'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: value => {
                  this.updateLegalEntityEav({ 'legal-entity-bank-name': value })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.legalEntity.eav['legal-entity-inn'],
                label: this.$t('INN'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: value => {
                  this.updateLegalEntityEav({ 'legal-entity-inn': value })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.legalEntity.eav['legal-entity-bank-bik'],
                label: this.$t('BIC'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: value => {
                  this.updateLegalEntityEav({ 'legal-entity-bank-bik': value })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.legalEntity.eav['legal-entity-bank-corresponding-account'],
                label: this.$t('Routing number'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: value => {
                  this.updateLegalEntityEav({ 'legal-entity-bank-corresponding-account': value })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.legalEntity.eav['legal-entity-payment-account'],
                label: this.$t('Account number'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: value => {
                  this.updateLegalEntityEav({ 'legal-entity-payment-account': value })
                }
              }
            ]
          }
        ]
      }
    },
    schemaEAV () {
      return {
        groups: this.eavs.reduce((acc, eav) => {
            const result = eav.element.filter(e=>{
              if(e.spec) {
                  if(e.spec.type === 'Laminas\\Form\\Element\\Image')  {
                      return false
                  } else {
                      return true
                  }
              } else {
                  if(e.type === 'Laminas\\Form\\Element\\Image')  {
                      return false
                  } else {
                      return true
                  }
              }
          })
          return [...acc, ...this.createSchema(result)]
        }, [])
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.legalEntity.name,
                label: this.$t('Name'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: name => {
                  this.updateLegalEntity({ name })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.legalEntity.contractNumber,
                label: this.$t('Service agreement number'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: contractNumber => {
                  this.updateLegalEntity({ contractNumber })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                value: this.tax,
                label: this.$t('Tax rate') + ' (%)',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: tax => {
                  this.tax = Number(tax).toFixed(2)
                  this.updateLegalEntity({ tax: (Number(this.tax) / 100) })
                }
              },
              {
                if: this.isSupervisior || this.isAdministrator,
                type: 'multiselect',
                label: this.$t('Owner'),
                field: 'owner',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.legalEntity._embedded.owner,
                disabled: !!this.legalEntity.id,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: []
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: owner => {
                  this.updateLegalEntity({
                    _embedded: {
                      ...this.legalEntity._embedded,
                      owner
                    }
                  })
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (!this.legalEntity) {
      this.setNewLegalEntity()
    }

    this.tax = (this.legalEntity.tax || 0) * 100

    if (this.type) {
      this.updateLegalEntity({ type: this.type })
      this.isDisableType = true
    }
    this.loadFields()
  },
  methods: {
    ...mapMutations([
      'updateLegalEntity',
      'updateLegalEntityEav',
      'setNewLegalEntity'
    ]),
    loadFields () {
      this.isLoading = true
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'backendEntity', value: this.$entities.Orderadmin_Accounts_Entity_LegalEntity }
        ]
      }

      return this.$service.eav.getAll(query)
          .then(data => {
              this.eavs = data.items
              return data
          })
          .finally(() => {
              this.isLoading = false
          })
    },
    createSchema (groups) {
      return [
        {
          styleClasses: 'row',
          fields: groups.reduce((acc, group) => {
            return [
              ...acc,
              ...Object.values(group).map(field => {

                const newField = this.$utils.field.create(this.legalEntity.eav, field.spec ? field.spec: field)
                newField.wrapperStyleClasses = 'col-12 q-pa-xs'

                return newField

              })
            ]
          }, [])
        }
      ]
    },
    handleEAVSubmit (eav) {
        this.updateLegalEntity({ eav })
        this.hasChange = true
    },
  }
}
</script>
