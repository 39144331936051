<template>
  <q-dialog v-model="isOpen">
    <assigned-accounts
      v-if="isOpen"
      style="min-width: 66vw;"
      type="modal"
      :rate="rate"
      :title="title"
      @close="close"
    />
  </q-dialog>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Components
import AssignedAccounts from '../account/AssignedAccounts.vue'

export default {
  name: 'AccountsModal',
  components: {
    AssignedAccounts
  },
  data () {
    return {
      title: this.$t('Accounts'),
      isOpen: false,
      rate: null
    }
  },
  methods: {
    ...mapMutations([
      'setAccounts'
    ]),
    close () {
      this.isOpen = false
    },
    open (rate, title = this.$t('Accounts')) {
      this.title = title
      this.rate = rate
      this.isOpen = true
    }
  }
}
</script>
