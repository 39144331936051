<template>
  <q-card class="q-ma-sm">
    <q-tabs
        :model-value="tab"
        dense
        align="justify"
        class="tab-list"
        @update:model-value="handleTabChange"
    >
      <q-tab
          v-for="tab in currentTabs"
          :key="tab.object"
          class="col"
          :disable="disabled"
          :name="tab.object"
          :label="$t(tab.name)"
          :class="getStyleClasses(tab)"
      >
        <rate-modifier-badge :items="rateModifiers[tab.object]"/>
      </q-tab>
    </q-tabs>

    <q-separator/>

    <div
        v-if="isLoading"
        class="row items-center justify-center"
        style="min-height: 200px"
    >
      <q-spinner color="primary" size="4rem" class="q-mb-md"/>
    </div>

    <div
        v-if="!isLoading && tabs.length <= 0"
        class="row items-center justify-center text-subtitle1"
        style="min-height: 200px"
    >
      {{ $t(`Please add service via 'Service' button!`) }}
    </div>

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel
          v-for="tabView in tabs"
          :key="`${tabView.object}`"
          :name="`${tabView.object}`"
      >
        <div
            v-if="
            tabView.object !== null &&
            tabView.object !== 'null' &&
            tabView.object !==
              'Orderadmin\\Storage\\Billing\\AcceptanceAdapter' &&
            rateModifiers[tabView.object].length > 1
          "
            class="text-center text-caption text-negative q-mb-md"
        >
          <q-icon name="warning" size="20px"/>

          {{ $t('We found more than one fee. Please stay only one fee.') }}
        </div>

        <div v-if="!isLoading" class="row justify-end items-center q-pb-md">
          <div v-if="info">
            <q-btn
                v-if="info"
                round
                size="sm"
                color="grey"
                icon="question_mark"
                @click="openInfo"
            />
          </div>

          <div v-if="getIsEnabledAdd(tabView)" class="q-ml-sm">
            <q-btn-dropdown
                v-if="tabView.object === 'Orderadmin\\Storage\\Billing\\AcceptanceAdapter'"
                icon="add"
                color="green-6"
                :label="$t('Rates and type')"
                :disable="disabled"
                no-caps
            >
              <q-list>
                <q-item
                    v-for="option in getOptions(tabView)"
                    :key="option.id"
                    clickable
                    v-close-popup
                    @click="handleAddFee(tabView, option)"
                >
                  <q-item-section>
                    <q-item-label>
                      {{ option.name }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>

            <q-btn
                v-else
                color="green-6"
                icon="add"
                :disabled="disabled"
                :label="$t('Rate')"
                @click="handleAddFee(tabView)"
            />
          </div>
        </div>
        <q-card
            v-for="rate in rateModifiers[tabView.object].filter(
            (x) => x.state === 'active'
          )"
            :key="rate.id"
            class="q-pt-sm q-mb-md"
        >
          <rate-modifier
              :name="tabView.name"
              :keywords="tabView.keywords"
              :model="rate"
              :is-custom="tabView.isCustom"
              :disabled="disabled"
              @change="handleChange(tabView.object, $event)"
              @delete="handleDelete"
          />
        </q-card>

        <div v-if="!isLoading && getIsEnabledAdd(tabView)">
          <q-btn
              v-if="
              tabView.object !==
              'Orderadmin\\Storage\\Billing\\AcceptanceAdapter'
            "
              color="green-6"
              :label="$t('Rate')"
              :disabled="disabled"
              @click="handleAddFee(tabView)"
          />
        </div>
      </q-tab-panel>
    </q-tab-panels>

    <confirm-value-modal ref="confirmValueModal"/>

    <info-data-modal ref="infoDataModal"/>

    <portal to="billing-header">
      <action-lazy-dropdown :option="dropdownSchema"/>
    </portal>
  </q-card>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Utils
import _ from 'lodash'

// Components
import RateModifier from './RateModifier.vue'
import RateModifierBadge from './RateModifierBadge.vue'
import ConfirmValueModal from '../modals/ConfirmValueModal.vue'
import ActionLazyDropdown from '../action-header/ActionLazyDropdown.vue'
import InfoDataModal from '../modals/InfoDataModal.vue'

export default {
  name: 'RateModifiers',
  emits: ['change'],
  components: {
    RateModifier,
    RateModifierBadge,
    ConfirmValueModal,
    ActionLazyDropdown,
    InfoDataModal
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    accountRate: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isLoading: false,
      tab: '',
      originalRateModifiers: {},
      rateModifiers: {},
      tabs: [],
      infoMap: {
        custom: [
          {
            title: 'The Type field',
            descriptions: [
              {
                value: 'Fixed',
                text: 'means exact amount from the «Value» field will be add or subtract from a user Balance'
              },
              {
                value: 'Percent',
                text: 'means what % of the amount, which you will enter during the transaction creating, will be add or subtract from a user Balance',
                caption: '1 = 100%;  0.5 = 50%; 1.2 = 120%'
              }
            ]
          },
          {
            title: 'The Value field',
            descriptions: [
              {
                value: 'Value',
                text: 'can be positive or negative number. Write «-» - for subtract and «+» - for add the entered value from/to a customer Balance'
              }
            ]
          },
          {
            title: 'The Event field',
            descriptions: [
              {
                value: 'Event',
                text: 'means the Orderadmin System event to create a particular transaction. Fill in the event in case you know it. It is optional'
              }
            ]
          }
        ]
      },
      acceptanceTypes: [
        { id: 'products', name: this.$t('Products') },
        { id: 'items', name: this.$t('Items') },
        { id: 'pallets', name: this.$t('Pallets') },
        { id: 'batch & EXP', name: this.$t('Batch & EXP') }
      ]
    }
  },
  computed: {
    dropdownSchema () {
      return {
        id: 'services',
        type: 'lazy-dropdown',
        label: this.$t('Service'),
        wrapperStyle: 'max-width: 120px;',
        disabled: this.isLoading || this.disabled,
        noAutoclose: true,
        options: [
          {
            id: 'custom',
            name: 'Custom',
            code: 'custom',
            object: null,
            type: 'income',
            isCustom: true,
            eav: { styleClasses: 'text-amber-6' },
            priority: 0
          }
        ],
        customIcon: (item) => {
          if (item && typeof item === 'object' && this.tabs.find(x => x.id === item.id)) {
            return {
              name: 'remove',
              color: 'negative'
            }
          }

          return {
            name: 'add',
            color: 'positive'
          }
        },
        customLabel: (item) => {
          if (item && typeof item === 'object') {
            return item.name
          }

          return item
        },
        onScroll: (search, page) => {
          const query = {
            per_page: 25,
            page,
            search,
            filter: []
          }

          return this.$service.RMCode.getAll(query)
        },
        onClick: (option) => {
          if (this.tabs.find(x => x.id === option.id)) {
            this.tabs = this.tabs.filter(x => x.id !== option.id)
            this.rateModifiers[option.object] = this.rateModifiers[option.object].map(x => ({
              ...x,
              state: 'inactive'
            }))
            this.handleTabChange(this.tabs[0] && this.tabs[0].object)
            return
          }

          this.addTab(option)
        }
      }
    },
    currentTabs () {
      return this.tabs
    },
    info () {
      if (this.infoMap[this.tab]) {
        return this.infoMap[this.tab]
      }

      // We stringify values before that
      if (this.tab === 'null') {
        return this.infoMap.custom
      }

      return null
    }
  },
  mounted () {
    if (this.accountRate && this.accountRate.id) {
      this.reset()
    }
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    openInfo () {
      this.$refs.infoDataModal.open(this.info)
    },
    getOptions (tabView) {
      const options = {
        'Orderadmin\\Storage\\Billing\\AcceptanceAdapter': () => {
          const types = (this.rateModifiers[tabView.object] || [])
              .filter(x => x.state === 'active')
              .map(x => {

                return ((x.settings.conditions || []).find(y => y.field === 'type') || {}).value
              })

          return this.acceptanceTypes.filter(x => !types.includes(x.id))
        }
      }

      if (typeof options[tabView.object] === 'function') {
        return options[tabView.object]()
      }

      return []
    },
    getIsEnabledAdd (tabView) {
      if (!tabView.object || tabView.isCustom) {
        return true
      }

      const values = (this.rateModifiers[tabView.object] || []).filter(x => x.state === 'active')

      if (tabView.object === 'Orderadmin\\Storage\\Billing\\AcceptanceAdapter') {
        return values.length < this.acceptanceTypes.length
      }

      return values.length <= 0
    },
    handleTabChange (value) {
      this.tab = `${value}`
      if ((this.rateModifiers[value] || []).length <= 0 && this.accountRate && this.accountRate.id) {
        this.loadModifiers(value, this.accountRate)
      }
    },
    getStyleClasses (item) {
      if (this.$q.dark.isActive) {
        return 'text-white'
      }

      if (!item.eav || !item.eav['style-classes']) {
        return 'text-dark'
      }

      return item.eav['style-classes']
    },
    createTabs (items) {
      return this.loadTabs(items)
          .then(tabs => {
            if (tabs[0]) {
              this.tab = `${tabs[0].object}`
              this.loadModifiers(this.tab, this.accountRate)
            }

            return tabs
          })
    },
    loadTabs (items) {
      return this.$service.RMCode.findItems(items)
          .then(data => {
            this.tabs = data.items.sort((a, b) => b.priority - a.priority)

            const hasCustomRMs = items.find(x => !this.tabs.find(t => t.object === x.object))

            if (hasCustomRMs) {
              this.tabs.push({
                id: 'custom',
                name: 'Custom',
                code: 'custom',
                object: null,
                type: 'income',
                isCustom: true,
                priority: 0
              })
            }

            this.originalRateModifiers = {}
            this.rateModifiers = {}

            this.tabs.forEach(tab => {
              this.originalRateModifiers[tab.object] = []
              this.rateModifiers[tab.object] = []
            })

            return this.tabs
          })
    },
    forceLoadAllTabs (tabs, accountRate, results = []) {
      if (tabs.length <= 0) {
        return Promise.resolve(results)
      }

      return this.loadModifiers(tabs[0].object, accountRate)
          .then(result => {
            results.push(result)
            return this.forceLoadAllTabs(tabs.slice(1), accountRate, results)
          })
    },
    copyFrom (accountRate) {
      return this.load(accountRate)
          .then(({ items }) => {
            return this.loadTabs(items)
          })
          .then(tabs => {
            this.tab = `${(tabs[0] || {}).object}`
            return this.forceLoadAllTabs([...tabs], accountRate)
          })
          .then(result => {
            this.tabs.forEach(tab => {
              this.rateModifiers[tab.object] = this.rateModifiers[tab.object].map(rate => {
                return {
                  ...rate,
                  account: undefined,
                  created: undefined,
                  limits: undefined,
                  method: undefined,
                  objectState: undefined,
                  rate: undefined,
                  updated: undefined,
                  _links: undefined
                }
              })

              this.originalRateModifiers[tab.object] = []
            })

            return result
          })
    },
    addTab (tab) {
      if (this.tabs.find(x => x.id === tab.id)) {
        this.addWarningNotification('Service is already added!')
        return
      }

      this.tabs.push(tab)
      this.tabs.sort((a, b) => b.priority - a.priority)
      this.originalRateModifiers[tab.object] = []
      this.rateModifiers[tab.object] = []
      this.tab = `${tab.object}`
      this.handleAddFee(tab)
    },
    reset () {
      return this.load(this.accountRate)
          .then(({ items }) => {
            if (items.length > 0) {
              return this.createTabs(items)
            }
          })
    },
    load (accountRate) {
      this.isLoading = true
      const query = {
        filter: [
          { type: 'eq', field: 'rate', value: accountRate.id },
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'in', field: 'type', values: ['fixed', 'percent', 'deposit', 'managed', 'universal'] }
        ],
        per_page: 250
      }

      return this.$service.rateModifier.getAllGroups(query)
          .finally(() => {
            this.isLoading = false
          })
    },
    handleDelete (id) {
      this.$emit('change', { id })
      const originalItem = this.originalRateModifiers[this.tab].find(x => x.id === id)

      if (originalItem) {
        const options = {
          title: `${this.$t('To delete the fee, please enter its name')} (${originalItem.name})`,
          requiredText: originalItem.name,
          label: originalItem.name
        }

        return this.$refs.confirmValueModal.open(options)
            .then(isOk => {
              if (isOk) {
                this.rateModifiers[this.tab] = this.rateModifiers[this.tab].map(x => {
                  return x.id === id
                      ? { ...x, state: 'inactive' }
                      : x
                })
              }
            })
      }

      this.rateModifiers[this.tab] = this.rateModifiers[this.tab].filter(x => x.id !== id)
    },
    fixValues (rate) {
      if (rate.object === 'Orderadmin\\Storage\\Billing\\AcceptanceAdapter') {
        const value = Number(rate.settings.rates)
        rate.settings.rates = isNaN(value)
            ? rate.settings.rates
            : value
      }

      return rate
    },
    getValues () {
      return Object.values(this.rateModifiers).reduce((acc, items) => {
        return [...acc, ...items.map(this.fixValues)]
      }, [])
    },
    getOriginalValues () {
      return Object.values(this.originalRateModifiers).reduce((acc, items) => {
        return [...acc, ...items]
      }, [])
    },
    handleChange (code, rate) {
      this.$emit('change', rate)
      this.rateModifiers = {
        ...this.rateModifiers,
        [code]: this.rateModifiers[code].map(x => {
          return x.id === rate.id
              ? rate
              : x
        })
      }
    },
    getSettings (code, option) {
      const codes = {
        'Orderadmin\\Storage\\Billing\\InsuranceAdapter': () => {
          return {
            rates: null
          }
        },
        'Orderadmin\\Storage\\Billing\\AcceptanceAdapter': () => {
          return {
            code: null,
            conditions: [
              { type: 'eq', field: 'type', value: option && option.id || 'products' }
            ],
            name: '',
            comment: '',
            surcharges: [],
            rates: []
          }
        },
        'Orderadmin\\Products\\Billing\\ShippingAdapter': () => {
          return {
            rates: {
              addBase: null,
              addCost: null,
              addItem: null,
              base: null,
              baseCost: null
            }
          }
        },
        'Orderadmin\\Products\\Billing\\ReturnAdapter': () => {
          return {
            rates: {
              partly_return: {
                code: null,
                item: null,
                order: null
              },
              return: {
                code: null,
                item: null,
                order: null
              }
            }
          }
        },
        default: () => {
          return {
            code: null,
            rates: []
          }
        },
        custom: () => {
          return {}
        }
      }

      if (!code) {
        return codes.custom()
      }

      if (!codes[code]) {
        return codes.default()
      }

      return codes[code]()
    },
    handleAddFee (tab, option) {
      const entity = {
        id: Math.floor(Math.random() * (9999999999) + 1),
        name: '',
        event: null,
        isUnique: !!tab.isUnique,
        limits: null,
        method: null,
        objectState: null,
        code: tab.code,
        object: tab.object,
        settings: this.getSettings(tab.object, option),
        comment: '',
        state: 'active',
        value: tab.type === 'income'
            ? +1.0000
            : -1.0000,
        type: tab.isCustom
            ? 'fixed'
            : 'managed'
      }

      if (tab.isCustom) {
        entity.value = null
      }

      this.rateModifiers = {
        ...this.rateModifiers,
        [tab.object]: [entity, ...this.rateModifiers[tab.object]]
      }

      this.$emit('change', entity)
    },
    loadModifiers (object, accountRate) {
      if (object === null || object === 'null') {
        const query = {
          per_page: 25,
          page: 1,
          filter: [
            { type: 'eq', field: 'rate', value: accountRate.id },
            { type: 'eq', field: 'state', value: 'active' },
            { type: 'in', field: 'type', values: ['fixed', 'percent'] }
          ]
        }

        query.filter.push({ type: 'isNull', field: 'object' })

        this.isLoading = true

        return this.$service.rateModifier.getAll(query)
            .then(({ items }) => {
              this.rateModifiers = {
                ...this.rateModifiers,
                null: items
              }

              this.originalRateModifiers.null = _.cloneDeep(items)
            })
            .finally(() => {
              this.isLoading = false
            })
      }

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'rate', value: accountRate.id },
          { type: 'eq', field: 'object', value: object },
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      this.isLoading = true

      return this.$service.rateModifier.getAll(query)
          .then(({ items }) => {
            this.rateModifiers = {
              ...this.rateModifiers,
              [object]: items.map(x => {
                if (x.settings) {
                  return x
                }

                return {
                  ...x,
                  settings: this.getSettings(object)
                }
              })
            }

            this.originalRateModifiers[object] = _.cloneDeep(items)
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>

<style>
.tab-list .q-tabs__content {
  flex-wrap: wrap !important;
}
</style>
