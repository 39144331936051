<template>
  <rate-modifier-charge-row
    :static-mode="staticMode"
    :model="model"
    :types="types"
    :type="((model.conditions || []).find(x => x.field === 'type') || {}).value"
    :keywords="keywords"
    :disabled="disabled"
    @change="handleChange"
    @delete="$emit('delete', model)"
  />
</template>

<script>
// Components
import RateModifierChargeRow from './RateModifierChargeRow.vue'

export default {
  name: 'RateModifierAcceptance',
  emits: ['change'],
  components: {
    RateModifierChargeRow
  },
  props: {
    keywords: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          rates: null
        }
      }
    }
  },
  data () {
    return {
      types: [
        { id: 'simple', name: this.$t('Simple') },
        { id: 'grouped', name: this.$t('Grouped') },
        { id: 'pallet', name: this.$t('Pallet') }
      ]
    }
  },
  methods: {
    handleChange (model) {
      this.$emit('change', model)
    }
  }
}
</script>
