<template>
  <q-badge
    v-if="errors.length > 0"
    color="red"
    :floating="true"
  >
    {{ errors.length }}

    <q-tooltip>
      <div
        v-for="(err, i) in errors"
        :key="i"
        class="text-caption"
      >
        <q-item-section>
          {{ err }}
        </q-item-section>
      </div>
    </q-tooltip>
  </q-badge>
</template>

<script>
export default {
  name: 'RateModifierBadge',
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    errors () {
      return this.items.reduce((acc, item) => {
        if (!item.name) {
          acc.push(`Please fill the name field.`)
        }

        if (!item.comment) {
          acc.push(`Please fill the comment field.`)
        }

        return acc
      }, [])
    }
  }
}
</script>
