<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 64vw;">
      <q-card-section class="row items-center">
        <div class="text-h6 text-center q-mr-sm">
          {{ $t('Select a billing') }}
        </div>

        <search
          dense
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="close"
        />
      </q-card-section>

      <q-card-section class="text-right">
        <q-btn
          :color="filterParams && filterParams.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />
      </q-card-section>

      <filter-collapse
        :is-open="isOpenFilter"
        :options="{
          defaultFilter: filterParams,
          fields: activatedFields,
          values: {
            states: statuses
          },
          style: {
            noGroups: true
          }
        }"
        @submit="handleFiltersSubmit"
        @close="openCloseFilters"
      />

      <q-card-section class="text-center">
        <q-table
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="items"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="loading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="onRowDblClick(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                key="name"
                :props="props"
              >
                {{ props.row.name }}
              </q-td>

              <q-td
                key="type"
                :props="props"
              >
                {{ props.row.type || '--' }}
              </q-td>

              <q-td
                key="owner"
                :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.owner">
                  {{ props.row._embedded.owner.name }}
                </span>

                <span v-else>
                  --
                </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                c
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Utils
import { buildQuery } from '../../utils/query-utils'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'

export default {
  name: 'AccountRateSelectModal',
  emits: ['submit'],
  components: {
    FilterCollapse,
    Search
  },
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        }
      ],
      stateColors: {
        active: 'success',
        inactive: 'dark text-white'
      },
      loading: false,
      items: [],
      isOpenFilter: false,
      rate: null,
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'id',
        'created.from',
        'created.to',
        'name',
        'owner'
      ],
      filterParams: [],
      totalAccountsNumber: 0
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    filterBtnText () {
      return this.filterParams && this.filterParams.length > 0
        ? this.$t('Filtered: ' + this.pagination.rowsNumber)
        : this.$t('Filter')
    }
  },
  methods: {
    onRowDblClick (item) {
      this.$emit('submit', item)
      this.close()
    },
    handleFiltersSubmit (filter) {
      this.onRequest({ pagination: { filter, page: 1 } })
      this.isOpenFilter = false
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (!query.per_page) {
        query.per_page = 25
      }

      if (!query.filter) {
        query.filter = []
      }

      if (!query.filter.find(x => x.field === 'state')) {
        query.filter.push({ type: 'eq', field: 'state', value: 'active' })
      }

      query['order-by'] = [
        { type: 'field', field: 'created', direction: 'desc' }
      ]

      this.filterParams = query.filter
      this.loading = true

      return this.$service.accountRate.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.items = items

          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.loading = false
        })
    },
    close () {
      this.isOpen = false
    },
    open () {
      this.isOpen = true
      this.onRequest()
    }
  }
}
</script>
