<template>
  <div class="">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
            :model-value="model.code"
            type="number"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :hint="$t('Add a code of an external document.')"
            :disable="disabled"
            :label="$t('Code (optional)')"
            @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
            :model-value="model.code"
            type="number"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :disable="disabled"
            :label="$t('Code')"
            @update:model-value="handleInput"
        />
      </div>

      <div class="q-py-sm q-px-xs row">
        <div class="col">
            <form-builder :schema="schema" />
        </div>
      </div>

      <div class="col">
        <q-toggle
                class="q-pl-sm q-pt-sm"
                :model-value="virtual"
                :label="$t('Virtual:')"
                :left-label="true"
                @update:model-value="handleVirtualChange"
        />
      </div>

    </div>

      <q-table
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="transformRatesToRows(model.rates)"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: 3, rowsNumber: 3 }"
        :rows-per-page-options="[]"
        :hide-bottom="true"
        virtual-scroll
        binary-state-sort
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="number">{{ props.rowIndex + 1 }}</q-td>
            <q-td key="name">{{ props.row.name }}</q-td>
            <q-td key="price">
              <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="props.row.price "
                @update:model-value="handleQuantityInput(props.row, $event)"
              />
            </q-td>
            <q-td key="unit">per item</q-td>
          </q-tr>
        </template>
      </q-table>
  </div>
  <div>
    <div class="border-top">
      <div class="items-center justify-center text-center">
        <q-toggle
                :model-value="bundleRates"
                :label="$t('Bundle Rates')"
                @update:model-value="handleBundleRates"
        />
      </div>
    </div>

    <div v-if="bundleRates" class="q-py-sm q-px-xs row">
      <div class="col">
        <q-input
                :model-value="price"
                type="text"
                color="white"
                standout="bg-teal text-white"
                label-color="light"
                :disable="disabled"
                :label="$t('Price')"
                @update:model-value="handlePriceInput"
        />
      </div>

      <div class="col">
        <q-toggle
                :model-value="bundleRateFirstFromRates"
                :label="$t('First item billed with main rate price')"
                @update:model-value="handleBundleRateFirstFromRates"
        />
      </div>

    </div>

    <q-table
            v-if="bundleRates"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="model&& model.bundleRates && model.bundleRates.rates ? model.bundleRates.rates : []"
            :columns="columnsBundle"
            :rows-per-page-options="[]"
            :hide-bottom="true"
            virtual-scroll
            binary-state-sort
            flat
            dense
    >
      <template v-slot:top-row="props">
        <th v-if="bundleRateFirstFromRates" :colspan="columnsBundle.length">
          <div class="text-center">
            {{ 'The price of the first item will be calculated regarding the main rate' }}
          </div>
        </th>
      </template>

      <template v-slot:body="props">
        <rate-modifier-bundle-rates-row
                :prev-row="getPrevRowBundle(props.rowIndex)"
                :next-row="getNextRowBundle(props.rowIndex)"
                :props="props"
                :disabled="disabled"
                :disabled-delete="model.bundleRates.rates.length <= 1"
                :static-mode="staticMode"
                @delete="handleDeleteBundle"
                @change="handleChangeBundle"
        />
      </template>
    </q-table>

    <div v-if="!staticMode && bundleRates" class="text-left q-pb-md q-pt-sm q-px-xs">
      <div
              class="clickable rounded q-px-sm q-py-md text-caption"
              :class="$q.dark.isActive ? 'bg-dark' : 'bg-grey-3 text-grey-7'"
              :disable="disabled"
              @click="handleAddBundle"
      >
        <span class="border-bottom" style="border-bottom-color: #757575 !important;">
          <q-icon name="add" />

          <span>
            {{ $t('Add a Row') }}...
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import RateModifierBundleRatesRow from "./RateModifierBundleRatesRow.vue";

export default {
  name: 'RateModifierRetail',
  components: {
    RateModifierBundleRatesRow
  },
  emits: ['change', 'delete', 'rate-change'],
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    code: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    rate: {
      type: Object,
      default () {
        return {
          virtual: false
        }
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          unit: null,
          rates: [],
          types: [],
          bundleRates: {
              price: null,
              bundleRateFirstFromRates : false,
              rates: []
          }
        }
      }
    }
  },
  data () {
    return {
      types: this.model.types || [],
      rows: ['unit', 'same', 'different'],
      virtual: this.rate.hasVirtual ? this.rate.hasVirtual: false,
      bundleRates : this.model.bundleRates ? true: false,
      bundleRateFirstFromRates : this.model && this.model.bundleRates && this.model.bundleRates.bundleRateFirstFromRates ? this.model.bundleRates.bundleRateFirstFromRates : false,
      price: this.model && this.model.bundleRates && this.model.bundleRates.price?this.model.bundleRates.price : null,
      typesOptions: [
        {
          name: 'Retail',
          code: 'Orderadmin\\Products\\Entity\\Order'
        },
        {
          name: 'Wholesale',
          code: 'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder'
        },
        {
          name: 'Return',
          code: 'Orderadmin\\Products\\Entity\\Order\\ReturnOrder'
        },
        {
          name: 'Disposal',
          code: 'Orderadmin\\Products\\Entity\\Order\\Disposal'
        }
      ],
      columns: [
        {
          label: '#',
          name: 'number',
          align: 'left'
        },
        {
          label: 'Name',
          name: 'name',
          align: 'left'
        },
        {
          label: 'Price(unit)',
          name: 'price',
          align: 'left'
        },
        {
          label: 'Unit',
          name: 'unit',
          align: 'left'
        }
      ],
      columnsBundle: [
        {
            label: this.$t('Min Quantity'),
            name: 'min_volume',
            align: 'center'
        },
        {
            label: this.$t('Max Quantity'),
            name: 'volume',
            align: 'center'
        },
        {
            label: this.$t('Rate'),
            name: 'price',
            align: 'center'
        },
        {
            label: this.$t('Code (optional)'),
            name: 'code',
            align: 'center',
            classes: this.code ? '' : 'd-none',
            headerClasses: this.code ? '' : 'd-none'
        },
        {
            label: '',
            name: 'actions',
            align: 'right',
            classes: this.staticMode
                ? 'd-none'
                : ''
        }
      ]
    }
  },
    computed: {
      schema () {
        return {
          groups: [
            {
              id: 'form',
              styleClasses: 'row',
              fields: [
                {
                  type: 'tag',
                  label: this.$t('Type'),
                  wrapperStyleClasses: 'col-12 q-pa-xs',
                  value: this.types,
                  options: this.typesOptions,
                  field: 'types',
                  customTag: (item) => {
                    if (item && typeof item === 'object') {
                      return item.name
                    } else {
                      const test = this.typesOptions.filter(e=>e.code === item)
                      if ( test.length > 0 ) {
                        return test[0].name
                      }
                    }
                  },
                  customListItem: (row) => {
                    return this.$t(row.name)
                  },
                  onRemove: (val) => {
                    this.types = this.types.filter(x => x !== val)
                    this.emitChange({
                      ...this.model,
                      types: this.types
                    })
                  },
                  onAdd: (value) => {
                    if (Array.isArray(value)) {
                      return
                    }

                    let isExist = false

                    this.types = this.types.filter(val => {
                      if (val === value.code) {
                        isExist = true
                        return false
                      }

                      return true
                    })

                    if (isExist) {
                      return
                    }

                    this.types = [...this.types, value.code]
                    this.emitChange({
                        ...this.model,
                        types: this.types
                    })
                  }
                }
              ]
            }
          ]
        }
      }
    },
    methods: {
      emitChange (model) {
          this.$emit('change', model)
      },
      emitChangeRate (model) {
          this.$emit('rate-change', model)
      },
      handleBundleRates () {
          this.bundleRates = !this.bundleRates
      },
      handleBundleRateFirstFromRates () {

          this.bundleRateFirstFromRates = !this.bundleRateFirstFromRates

          this.emitChange({
              ...this.model,
              bundleRates: {
                  ...this.model.bundleRates,
                  bundleRateFirstFromRates: this.bundleRateFirstFromRates
              }
          })
      },
      getPrevRowBundle (index) {
          const rates = (this.model.bundleRates.rates || [])
          return rates[index - 1] || null
      },
      getNextRowBundle (index) {
          const rates = (this.model.bundleRates.rates || [])
          return rates[index + 1] || null
      },
      handleInput (code) {
          this.emitChange({
              ...this.model,
              code
          })
      },
      handlePriceInput (price) {
          this.price = price

          this.emitChange({
            ...this.model,
              bundleRates: {
                ...this.model.bundleRates,
                price
              }
          })
      },
      handleDeleteBundle (rowIndex) {
          const update = {
              ...this.model,
              bundleRates: {
                  ...this.model.bundleRates,
                  rates: this.model.bundleRates.rates.filter((x, i) => i !== rowIndex)
              }
          }

          this.emitChange(update)
      },
      handleAddBundle () {
          if (this.disabled) {
              return
          }
          if(this.model && this.model.bundleRates && this.model.bundleRates.rates) {
              const lastRate = this.model.bundleRates.rates[this.model.bundleRates.rates.length - 1]
              const newRow = lastRate
                  ? { price: 0, count: Number(lastRate.count) + 1 }
                  : { price: 0, count: 0 }

              const update = {
                  ...this.model,
                  bundleRates: {
                      ...this.model.bundleRates,
                      rates: [
                          ...this.model.bundleRates.rates,
                          newRow
                      ]
                  }
              }

              this.emitChange(update)
          } else {
              const newRow = { price: 0, count: 0 }

              const update = {
                  ...this.model,
                  bundleRates: {
                      ...this.model.bundleRates,
                      rates: [
                          newRow
                      ]
                  }
              }

              this.emitChange(update)
          }

      },
      handleVirtualChange () {
          this.virtual = !this.virtual
          const update = {
              ...this.rate,
              hasVirtual: this.virtual
          }
          this.emitChangeRate(update)
      },
      handleChangeBundle ({ rowIndex, row }) {
          const update = {
              ...this.model,
              bundleRates: {
                  ...this.model.bundleRates,
                  rates: [
                      ...this.model.bundleRates.rates.slice(0, rowIndex),
                      row,
                      ...this.model.bundleRates.rates.slice(rowIndex + 1)
                  ]
              }

          }

          this.emitChange(update)
      },
      transformRatesToRows(rates) {
        const rows = [];
        const names = {
          unit: 'First Item of first SKU',
          same: 'Second and each following item with any SKU',
          different: 'First item with different SKU'
        };

        const desiredOrder = ['unit', 'different', 'same'];
        if(rates && (rates.same && rates.different && rates.unit) ) {
          desiredOrder.forEach((key, index) => {
            if (Object.prototype.hasOwnProperty.call(rates, key)) {
              rows.push({
                number: index + 1,
                name: names[key],
                price: rates[key],
                field: key
              });
            }
          });
        } else {
          desiredOrder.forEach((key, index) => {
            if (rates[key]){
              if (Object.prototype.hasOwnProperty.call(rates, key)) {
                rows.push({
                  number: index + 1,
                  name: names[key],
                  price: rates[key],
                  field: key
                });
              }
            } else {
              rows.push({
                number: index + 1,
                name: names[key],
                price: '',
                field: key
              });
            }
          });
        }

        return rows;
      },
      handleQuantityInput (tableRow, value) {
        const update = {
          ...this.model,
          rates: {
            ...this.model.rates,
            [tableRow.field]: Number(value)
          }
        }

        this.emitChange(update)

      }
    }
}
</script>
