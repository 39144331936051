<template>
  <div class="q-px-md q-pb-md">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <template v-if="portal" to="rate-modifier-form">

          <q-input
              :model-value="model.code"
              type="text"
              color="white"
              standout="bg-teal text-white"
              label-color="light"
              :hint="$t('Add a code of an external document.')"
              :disable="disabled"
              :label="$t('Code (optional)')"
              @update:model-value="handleInput"
          />

          <div class="q-pa-md">
            <div class="q-gutter-md">
              <q-badge color="secondary" multi-line>
                Model: "{{ model.filters }}"
              </q-badge>

              <q-select
                  filled
                  :model-value="model.filters"
                  option-label="label"
                  :options="filterOptions"
                  stack-label
                  label="Standard"
                  @filter="filterFn"
                  @update:model-value="handleFilterChange"
              />
            </div>
          </div>
        </template>
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :disable="disabled"
            :label="$t('Code')"
            @update:model-value="handleInput"
        />
      </div>
    </div>

    <h5 style="background-color: lightblue"   class="text-center q-my-md" >
      {{ $t('Primary') }}
    </h5>

    <div class="row">
      <div class="col-4">
        <form-builder :schema="unitSchema"/>
      </div>

      <div class="col q-pa-md text-subtitle1">
        <span style="font-weight:bold">{{$t('How it works:')}}</span>
        <br/>
        <span>
          {{ $t('The order processing up to ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.unit }}
        </span>

        <span>
          {{ ' ' + $t('item(s) in the order and up to ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.base }}
        </span>

        <span>
          {{ ' ' + $t('weight units of the order weight charges ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.baseCost }}
        </span>
        .
      </div>
    </div>

    <h5 style="background-color: lightblue" class="text-center q-my-md">
      {{ $t('Additional order weight') }}
    </h5>

    <div class="row">
      <div class="col-4">
        <form-builder :schema="additionalWeightSchema"/>
      </div>

      <div class="col q-pa-md text-subtitle1">
        <span style="font-weight:bold">{{$t('How it works:')}}</span>
        <br/>
        <span>
          {{ $t('Each additional ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.addBase }}
        </span>

        <span>
          {{ ' ' + $t('weight units of the order charges ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.addCost }}
        </span>
        .
      </div>
    </div>

    <h5 style="background-color: lightblue" class="text-center q-my-md">
      {{ $t('Additional item') }}
    </h5>

    <div class="row">
      <div class="col-4">
        <form-builder :schema="additionalItemSchema"/>
      </div>

      <div class="col q-pa-md text-subtitle1">
        <span style="font-weight:bold">{{$t('How it works:')}}</span>
        <br/>
        <span>
          {{ $t('Each additional item in the order charges ') }}
        </span>

        <span
            class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
            style="min-width: 50px; display: inline-block;"
        >
          {{ model.rates.addItem }}
        </span>
        .
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RateModifierShipment',
  emits: ['change'],
  props: {
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          rates: {
            addBase: null,
            addCost: null,
            addItem: null,
            base: null,
            baseCost: null
          },
          filters: [
            {
              type: '',
              field: '',
              value: '',
              label: ''
            }
          ]
        }
      }
    }
  },
  computed: {
    unitSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'number',
                field: 'unit',
                value: this.model.rates.unit,
                disabled: this.staticMode || this.disabled,
                wrapperStyleClasses: 'q-py-xs',
                label: this.$t('Item(s)'),
                onChange: (unit) => {
                  this.update({ unit })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'base',
                value: this.model.rates.base,
                disabled: this.staticMode || this.disabled,
                label: this.$t('Order weight'),
                wrapperStyleClasses: 'q-py-xs',
                onChange: (base) => {
                  this.update({ base })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'baseCost',
                disabled: this.staticMode || this.disabled,
                value: this.model.rates.baseCost,
                label: this.$t('Rate'),
                wrapperStyleClasses: 'q-py-xs',
                onChange: (baseCost) => {
                  this.update({ baseCost })
                }
              }
            ]
          }
        ]
      }
    },
    additionalWeightSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'addBase',
                value: this.model.rates.addBase,
                label: this.$t('Order weight'),
                disabled: this.staticMode || this.disabled,
                wrapperStyleClasses: 'q-py-xs',
                onChange: (addBase) => {
                  this.update({ addBase })
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'addCost',
                value: this.model.rates.addCost,
                disabled: this.staticMode || this.disabled,
                label: this.$t('Rate'),
                wrapperStyleClasses: 'q-py-xs',
                onChange: (addCost) => {
                  this.update({ addCost })
                }
              }
            ]
          }
        ]
      }
    },
    filterOptions () {
      return [
        {
          label: ''
        },
        {
          type: 'eq',
          field: 'type',
          value: 'retail',
          label: 'Retail'
        },
        {
          type: 'eq',
          field: 'type',
          value: 'wholesale',
          label: 'Wholesale'
        }
      ]
    },
    additionalItemSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'number',
                field: 'addItem',
                value: this.model.rates.addItem,
                disabled: this.staticMode || this.disabled,
                label: this.$t('Rate'),
                wrapperStyleClasses: 'q-py-xs',
                onChange: (addItem) => {
                  this.update({ addItem })
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    update (update) {
      this.$emit('change', { ...this.model, rates: { ...this.model.rates, ...update } })
    },
    handleInput (code) {
      this.$emit('change', {
        ...this.model,
        code
      })
    },
    filterFn (val, update) {
      update(() => {
        if (val === '') {
          this.filterOptions = this.filterOptions.filter(
              v => v.value === val.value
          )
        }
      })
    },
    handleFilterChange (val) {
      const filter = {
        type: val.type,
        field: val.field,
        value: val.value
      }
      this.$emit('change', { ...this.model, filters: [filter] })

      // if (val.length > 0) {
      //   const model = (this.model.rates.filters || []).slice()
      //
      //   done(null)
      //   this.model.rates.filters = model
      // }

      // console.log(val)
      // this.$emit('change', {
      //   ...this.model,
      //   rates : {
      //     ...this.model.rates,
      //     filters: [
      //       {
      //         type: "eq",
      //         field: "type",
      //         value: '2'
      //       }
      //     ]
      //   }
      // })
    }
  }
}
</script>
