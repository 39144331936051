<template>
  <div>
    <q-table
      v-if="model.length > 0"
      row-key="id"
      :rows-per-page-label="$t('Rows per page')"
      :rows="model"
      :columns="columns"
      :pagination="{ page: 1, rowsPerPage: model.length, rowsNumber: model.length }"
      :rows-per-page-options="[]"
      :hide-bottom="true"
      virtual-scroll
      binary-state-sort
      dense
      flat
    >
      <template v-slot:body="props">
        <q-tr
          :props="props"
          class="clickable"
        >
          <q-td
            key="name"
            :props="props"
            style="border-bottom-width: 0 !important;"
          >
            {{ (surchargesOptions.find(x => x.id === props.row.type) || { name: props.row.type }).name }}
          </q-td>

          <q-td
            key="rate"
            :props="props"
            style="border-bottom-width: 0 !important;"
          >
            <q-input
              type="number"
              :disable="!!staticMode || disabled"
              :model-value="props.row.rate"
              @update:model-value="handleUpdate(props.rowIndex, 'rate', $event)"
            />
          </q-td>

          <q-td
            key="code"
            :props="props"
            style="border-bottom-width: 0 !important;"
          >
            <q-input
              type="number"
              :disable="!!staticMode || disabled"
              :model-value="props.row.code"
              @update:model-value="handleUpdate(props.rowIndex, 'code', $event)"
            />
          </q-td>

          <q-td
            key="actions"
            :props="props"
            auto-width
            style="border-bottom-width: 0 !important;"
          >
            <q-btn
              v-if="!staticMode"
              color="negative"
              icon="close"
              flat
              :disable="disabled"
              @click="handleDelete(props.rowIndex)"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
export default {
  name: 'RateModifierSurcharge',
  emits: ['change'],
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      columns: [
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left'
        },
        {
          label: this.$t('Rate'),
          name: 'rate',
          align: 'left'
        },
        {
          label: this.$t('Code (optional)'),
          name: 'code',
          align: 'left'
        },
        {
          label: '',
          name: 'actions',
          align: 'right'
        },
      ],
      surchargesOptions: [
        { id: 'barcode', name: 'Print barcode charge' }
      ]
    }
  },
  computed: {
    options () {
      return this.surchargesOptions.filter(x => !this.model.find(y => y.type === x.id))
    },
  },
  methods: {
    handleDelete (index) {
      const surcharges = this.model.filter((x, i) => i !== index)
      this.$emit('change', surcharges)
    },
    handleUpdate (index, key, value) {
      const surcharges = [...this.model]
      surcharges[index][key] = value
      this.$emit('change', surcharges)
    },
    handleClick (option) {
      const surcharges = [
        { code: null, type: option.id, rate: 0 },
        ...this.model
      ]

      this.$emit('change', surcharges)
    }
  }
}
</script>
