<template>
  <div class="">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :hint="$t('Add a code of an external document.')"
            :disable="disabled"
            :label="$t('Code (optional)')"
            @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :disable="disabled"
            :label="$t('Code')"
            @update:model-value="handleInput"
        />
      </div>

      <div class="q-py-sm q-px-xs row">
<!--        <div class="col">-->
<!--          <q-input-->
<!--              :model-value="unit"-->
<!--              type="text"-->
<!--              color="white"-->
<!--              standout="bg-teal text-white"-->
<!--              label-color="light"-->
<!--              :disable="disabled"-->
<!--              :label="$t('Unit')"-->
<!--              @update:model-value="handleUnitInput"-->
<!--          />-->
<!--        </div>-->

        <div class="col">
            <form-builder :schema="schema" />
        </div>

        <div class="col">
          <q-toggle
              class="q-pl-sm q-pt-sm"
              :model-value="accumulative"
              :label="$t('Accumulative:')"
              :left-label="true"
              @update:model-value="handleAccumulative"
          />
          <q-toggle
              class="q-pl-sm q-pt-sm"
              :model-value="virtual"
              :label="$t('Virtual:')"
              :left-label="true"
              @update:model-value="handleVirtualChange"
          />
        </div>

      </div>
    </div>

    <q-table
        v-if="model.rates.length > 0"
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="model.rates"
        :columns="columns"
        :pagination="{ page: 1, rowsPerPage: model.rates.length, rowsNumber: model.rates.length }"
        :rows-per-page-options="[]"
        :hide-bottom="true"
        virtual-scroll
        binary-state-sort
        flat
        dense
    >
      <template v-slot:body="props">
        <rate-modifier-tiered-table-row
            :prev-row="getPrevRow(props.rowIndex)"
            :next-row="getNextRow(props.rowIndex)"
            :props="props"
            :disabled="disabled"
            :disabled-delete="model.rates.length <= 1"
            :static-mode="staticMode"
            @delete="handleDelete"
            @change="handleChange"
        />
      </template>
    </q-table>

    <div v-if="!staticMode" class="text-left q-pb-md q-pt-sm q-px-xs">
      <div
          class="clickable rounded q-px-sm q-py-md text-caption"
          :class="$q.dark.isActive ? 'bg-dark' : 'bg-grey-3 text-grey-7'"
          :disable="disabled"
          @click="handleAdd"
      >
        <span class="border-bottom" style="border-bottom-color: #757575 !important;">
          <q-icon name="add" />

          <span>
            {{ $t('Add a Row') }}...
          </span>
        </span>
      </div>
    </div>
  </div>
  <div>
      <div class="border-top">
          <div class="items-center justify-center text-center">
              <q-toggle
                      :model-value="bundleRates"
                      :label="$t('Bundle Rates')"
                      @update:model-value="handleBundleRates"
              />
          </div>
      </div>

      <div v-if="bundleRates" class="q-py-sm q-px-xs row">
          <div class="col">
              <q-input
                      :model-value="price"
                      type="text"
                      color="white"
                      standout="bg-teal text-white"
                      label-color="light"
                      :disable="isBundleRateFirstFromRatesDisabled"
                      :label="$t('Price')"
                      @update:model-value="handlePriceInput"
              />
          </div>

          <div class="col">
              <q-toggle
                      :model-value="bundleRateFirstFromRates"
                      class="q-pl-sm q-pt-sm"
                      :label="$t('First item billed with main rate price')"
                      @update:model-value="handleBundleRateFirstFromRates"
                      :disable="isBundleRateFirstFromRatesDisabled"
              />

              <q-toggle
                      :model-value="noQuantity"
                      class="q-pl-sm q-pt-sm"
                      :label="$t('Don’t use quantity')"
                      @update:model-value="handleBundleRateUseQuantity"
              />
          </div>

      </div>

      <q-table
              v-if="bundleRates"
              row-key="id"
              :rows-per-page-label="$t('Rows per page')"
              :rows="model&& model.bundleRates && model.bundleRates.rates ? model.bundleRates.rates : []"
              :columns="columns"
              :rows-per-page-options="[]"
              :hide-bottom="true"
              virtual-scroll
              binary-state-sort
              flat
              dense
      >
          <template v-slot:top-row="props">
              <th v-if="bundleRateFirstFromRates" :colspan="columns.length">
                  <div class="text-center">
                      {{ 'The price of the first item will be calculated regarding the main rate' }}
                  </div>
              </th>
          </template>

          <template v-slot:body="props">
              <rate-modifier-bundle-rates-row
                      :prev-row="getPrevRowBundle(props.rowIndex)"
                      :next-row="getNextRowBundle(props.rowIndex)"
                      :props="props"
                      :disabled="disabled"
                      :disabled-delete="model.bundleRates.rates.length <= 1"
                      :static-mode="staticMode"
                      @delete="handleDeleteBundle"
                      @change="handleChangeBundle"
              />
          </template>
      </q-table>

      <div v-if="!staticMode && bundleRates" class="text-left q-pb-md q-pt-sm q-px-xs">
          <div
                  class="clickable rounded q-px-sm q-py-md text-caption"
                  :class="$q.dark.isActive ? 'bg-dark' : 'bg-grey-3 text-grey-7'"
                  :disable="disabled"
                  @click="handleAddBundle"
          >
        <span class="border-bottom" style="border-bottom-color: #757575 !important;">
          <q-icon name="add" />

          <span>
            {{ $t('Add a Row') }}...
          </span>
        </span>
          </div>
      </div>
  </div>
</template>

<script>
// Components
import RateModifierTieredTableRow from './RateModifierTieredTableRow.vue'
import RateModifierBundleRatesRow from "./RateModifierBundleRatesRow.vue";

export default {
  name: 'RateModifierTiered',
  emits: ['change', 'delete', 'rate-change'],
  components: {
    RateModifierTieredTableRow,
    RateModifierBundleRatesRow
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    code: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    rate: {
      type: Object,
      default () {
        return {
          virtual: false
        }
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          unit: null,
          rates: [],
          types: [],
          accumulative: false,
          bundleRates: {
            price: null,
            bundleRateFirstFromRates : false,
            rates: []
          }
        }
      }
    }
  },
  data () {
    return {
      unit: this.model.unit,
      virtual: this.rate.hasVirtual ? this.rate.hasVirtual: false,
      accumulative: this.model.accumulative || false,
      bundleRates : this.model.bundleRates ? true: false,
      bundleRateFirstFromRates : this.model?.bundleRates && this.model.bundleRates.bundleRateFirstFromRates ? this.model.bundleRates.bundleRateFirstFromRates : false,
      noQuantity: this.model && this.model.bundleRates && this.model.bundleRates.noQuantity ? this.model.bundleRates.noQuantity : false,
      price: this.model && this.model.bundleRates && this.model.bundleRates.price?this.model.bundleRates.price : null,
      types: this.model.types || [],
      typesOptions: [
        {
          name: 'Retail',
          code: 'Orderadmin\\Products\\Entity\\Order'
        },
        {
          name: 'Wholesale',
          code: 'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder'
        },
        {
          name: 'Return',
          code: 'Orderadmin\\Products\\Entity\\Order\\ReturnOrder'
        },
        {
          name: 'Disposal',
          code: 'Orderadmin\\Products\\Entity\\Order\\Disposal'
        }
      ],
      columns: [
        {
          label: this.$t('Min Quantity'),
          name: 'min_volume',
          align: 'center'
        },
        {
          label: this.$t('Max Quantity'),
          name: 'volume',
          align: 'center'
        },
        {
          label: this.$t('Rate'),
          name: 'price',
          align: 'center'
        },
        {
          label: this.$t('Code (optional)'),
          name: 'code',
          align: 'center',
          classes: this.code ? '' : 'd-none',
          headerClasses: this.code ? '' : 'd-none'
        },
        {
          label: '',
          name: 'actions',
          align: 'right',
          classes: this.staticMode
              ? 'd-none'
              : ''
        }
      ]
    }
  },
  computed: {
    showTopRow() {
      return this.bundleRates;
    },
    schema () {
      return {
        groups: [
          {
            id: 'form',
              styleClasses: 'row',
              fields: [
                {
                  type: 'tag',
                  label: this.$t('Type'),
                  wrapperStyleClasses: 'col-12 q-pa-xs',
                  value: this.types,
                  options: this.typesOptions,
                  field: 'types',
                  customTag: (item) => {
                    if (item && typeof item === 'object') {
                      return item.name
                    } else {
                      const test = this.typesOptions.filter(e=>e.code === item)
                      if ( test.length > 0 ) {
                        return test[0].name
                      }
                    }
                  },
                  customListItem: (row) => {
                      return this.$t(row.name)
                  },
                  onRemove: (val) => {
                      this.types = this.types.filter(x => x !== val)
                      this.emitChange({
                          ...this.model,
                          types: this.types
                      })
                  },
                  onAdd: (value) => {
                    if (Array.isArray(value)) {
                      return
                    }

                    let isExist = false

                    this.types = this.types.filter(val => {
                      if (val === value.code) {
                          isExist = true
                          return false
                      }

                      return true
                    })

                    if (isExist) {
                      return
                    }

                    this.types = [...this.types, value.code]
                    this.emitChange({
                        ...this.model,
                        types: this.types
                    })
                  }
                }
              ]
            }
        ]
      }
    },
    isBundleRateFirstFromRatesDisabled() {
      return this.disabled || this.noQuantity
    }
  },
  methods: {
    handleRemove () {
      this.$emit('delete', this.model)
    },
    emitChange (model) {
      this.$emit('change', model)
    },
    emitChangeRate (model) {
      this.$emit('rate-change', model)
    },
    handleBundleRates () {
      this.bundleRates = !this.bundleRates
    },
    handleBundleRateFirstFromRates () {

        this.bundleRateFirstFromRates = !this.bundleRateFirstFromRates

        this.emitChange({
            ...this.model,
            bundleRates: {
              ...this.model.bundleRates,
              bundleRateFirstFromRates: this.bundleRateFirstFromRates
            }
        })
    },
    handleBundleRateUseQuantity() {
      this.noQuantity = !this.noQuantity

      this.emitChange({
        ...this.model,
        bundleRates: {
          ...this.model.bundleRates,
          noQuantity: this.noQuantity
        }
      })
    },
    handleAccumulative () {
      this.accumulative = !this.accumulative
      this.emitChange({
        ...this.model,
        accumulative: this.accumulative
      })
    },
    getPrevRow (index) {
      const rates = (this.model.rates || [])
      return rates[index - 1] || null
    },
    getNextRow (index) {
      const rates = (this.model.rates || [])
      return rates[index + 1] || null
    },
    getPrevRowBundle (index) {
        const rates = (this.model.bundleRates.rates || [])
        return rates[index - 1] || null
    },
    getNextRowBundle (index) {
        const rates = (this.model.bundleRates.rates || [])
        return rates[index + 1] || null
    },
    handleInput (code) {
      this.emitChange({
        ...this.model,
        code
      })
    },
    handleUnitInput (unit) {
      this.unit = unit

        if( this.model.unit !== this.unit) {
          this.emitChange({
            ...this.model,
            unit
          })
        }
    },
    handlePriceInput (price) {
      this.price = price

        this.emitChange({
            ...this.model,
            bundleRates: {
              ...this.model.bundleRates,
              price
            }
        })
    },
    handleDelete (rowIndex) {
      const update = {
        ...this.model,
        rates: this.model.rates.filter((x, i) => i !== rowIndex)
      }

      this.emitChange(update)
    },
    handleDeleteBundle (rowIndex) {
        const update = {
            ...this.model,
            bundleRates: {
              ...this.model.bundleRates,
              rates: this.model.bundleRates.rates.filter((x, i) => i !== rowIndex)
            }
        }

        this.emitChange(update)
    },
    handleAdd () {
      if (this.disabled) {
        return
      }

      const lastRate = this.model.rates[this.model.rates.length - 1]
      const newRow = lastRate
          ? { price: 0, count: Number(lastRate.count) + 1 }
          : { price: 0, count: 0 }

      const update = {
        ...this.model,
        rates: [
          ...this.model.rates,
          newRow
        ]
      }

      this.emitChange(update)
    },
    handleAddBundle () {
        if (this.disabled) {
            return
        }
        if(this.model && this.model.bundleRates && this.model.bundleRates.rates) {
            const lastRate = this.model.bundleRates.rates[this.model.bundleRates.rates.length - 1]
            const newRow = lastRate
                ? { price: 0, count: Number(lastRate.count) + 1 }
                : { price: 0, count: 0 }

            const update = {
                ...this.model,
                bundleRates: {
                    ...this.model.bundleRates,
                    rates: [
                        ...this.model.bundleRates.rates,
                        newRow
                    ]
                }
            }

            this.emitChange(update)
        } else {
            const newRow = { price: 0, count: 0 }

            const update = {
                ...this.model,
                bundleRates: {
                    ...this.model.bundleRates,
                    rates: [
                        newRow
                    ]
                }
            }

            this.emitChange(update)
        }

      },
    handleChange ({ rowIndex, row }) {
      const update = {
        ...this.model,
        rates: [
          ...this.model.rates.slice(0, rowIndex),
          row,
          ...this.model.rates.slice(rowIndex + 1)
        ]
      }

      this.emitChange(update)
    },
    handleVirtualChange () {
      this.virtual = !this.virtual
      const update = {
          ...this.rate,
          hasVirtual: this.virtual
      }
      this.emitChangeRate(update)
    },
    handleChangeBundle ({ rowIndex, row }) {
        const update = {
            ...this.model,
            bundleRates: {
              ...this.model.bundleRates,
              rates: [
                  ...this.model.bundleRates.rates.slice(0, rowIndex),
                  row,
                  ...this.model.bundleRates.rates.slice(rowIndex + 1)
              ]
            }

        }

        this.emitChange(update)
    }
  }
}
</script>
