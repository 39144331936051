<template>
  <div class="q-px-md q-pb-md">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
          :model-value="model.code"
          type="text"
          color="white"
          standout="bg-teal text-white"
          label-color="light"
          :hint="$t('Add a code of an external document.')"
          :disable="disabled"
          :label="$t('Code (optional)')"
          @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
        <q-input
          :model-value="model.code"
          type="text"
          color="white"
          standout="bg-teal text-white"
          label-color="light"
          :disable="disabled"
          :label="$t('Code')"
          @update:model-value="handleInput"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <form-builder :schema="schema" />
      </div>

      <div v-if="model.type === 'percent'" class="col q-pa-md text-subtitle1">
        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
          style="min-width: 50px; display: inline-block; border-bottom-color: #4caf50 !important;"
        >
          {{ (model.value || 0) * 100 }}
        </span>

        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6 q-ml-sm"
          style="min-width: 50px; display: inline-block; border-bottom-color: #9c27b0 !important;"
        >
          %
        </span>

        <span>
          {{ ' ' + $t('of an amount (which you will enter during new transaction creating) will be') + ' ' }}
        </span>

        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
          style="min-width: 50px; display: inline-block;"
        >
          {{  $t(Number(model.value) > 0 ? 'added to' : 'substract from') }}
        </span>

        <span>
          {{ ' ' + $t('a user balance') }}
        </span>
        .
      </div>

      <div v-else-if="model.type === 'fixed'" class="col q-pa-md text-subtitle1">
        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
          style="min-width: 50px; display: inline-block; border-bottom-color: #4caf50 !important;"
        >
          {{ model.value }}
        </span>

        <span>
          {{ ' ' + $t('currency units will be') + ' ' }}
        </span>

        <span
          class="border-bottom border-bottom--bold text-center text-weight-bold text-h6"
          style="min-width: 50px; display: inline-block;"
        >
          {{  $t(Number(model.value) > 0 ? 'added to' : 'substract from') }}
        </span>

        <span>
          {{ ' ' + $t('a user balance') }}
        </span>
        .
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RateModifierReturns',
  emits: ['change'],
  props: {
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      types: [
        { id: 'fixed', name: this.$t('Fixed') },
        { id: 'percent', name: this.$t('Percent') }
        // { id: 'managed', name: this.$t('Managed') }
      ]
    }
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'number',
                field: 'value',
                value: this.model.value,
                required: true,
                disabled: this.staticMode || this.disabled,
                color: 'green-6',
                label: this.$t('Value'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (value) => {
                  this.$emit('change', { ...this.model, value })
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                field: 'type',
                disabled: this.staticMode || this.disabled,
                value: this.model.type,
                color: 'purple',
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                required: true,
                options: this.types,
                onChange: (type) => {
                  this.$emit('change', { ...this.model, type: type.id })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'event',
                value: this.model.event,
                disabled: this.staticMode || this.disabled,
                label: this.$t('Event (optional)'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (event) => {
                  this.$emit('change', { ...this.model, event })
                }
              }
            ]
          }
        ]
      }
    }
  }
}
</script>
