<template>
  <div class="">
    <div v-if="!staticMode && !noCodeField">
      <portal v-if="portal" to="rate-modifier-form">
        <q-input
          :model-value="model.code"
          type="text"
          color="white"
          standout="bg-teal text-white"
          label-color="light"
          :hint="$t('Add a code of an external document.')"
          :disable="disabled"
          :label="$t('Code (optional)')"
          @update:model-value="handleInput"
        />
      </portal>

      <div v-else class="q-py-sm q-px-xs">
          <q-input
            :model-value="model.code"
            type="text"
            color="white"
            standout="bg-teal text-white"
            label-color="light"
            :disable="disabled"
            :label="$t('Code')"
            @update:model-value="handleInput"
          />
        </div>

          <div class="q-py-sm q-px-xs row">

            <div class="col">
              <form-builder :schema="schema" />
            </div>

            <div class="col">
              <div class="row">
                <div class="col-8">
                  <form-builder :schema="schema2" />
                </div>
                <q-toggle
                    class="col-3"
                    :model-value="virtual"
                    :left-label="true"
                    :label="$t('Virtual:')"
                    @update:model-value="handleVirtual"
                />
              </div>



            </div>
<!--            <div class="row q-gutter-md">-->

<!--              <q-select-->
<!--                  class="col-8"-->
<!--                  filled-->
<!--                  :model-value="model.period"-->
<!--                  option-label="label"-->
<!--                  :options="periods"-->
<!--                  stack-label-->
<!--                  label="Period"-->
<!--                  @update:model-value="handleClick"-->
<!--              />-->
<!--              <q-toggle-->
<!--                  class="col-3"-->
<!--                  :model-value="virtual"-->
<!--                  :label="$t('Virtual:')"-->
<!--                  :left-label="true"-->
<!--                  @update:model-value="handleVirtual"-->
<!--              />-->
<!--            </div>-->
      </div>
    </div>

    <q-table
      v-if="model.rates.length > 0"
      row-key="id"
      :rows-per-page-label="$t('Rows per page')"
      :rows="model.rates"
      :columns="columns"
      :pagination="{ page: 1, rowsPerPage: model.rates.length, rowsNumber: model.rates.length }"
      :rows-per-page-options="[]"
      :hide-bottom="true"
      virtual-scroll
      binary-state-sort
      flat
      dense
    >
      <template v-slot:body="props">
        <rate-modifier-services-table-row
          :prev-row="getPrevRow(props.rowIndex)"
          :next-row="getNextRow(props.rowIndex)"
          :props="props"
          :supplies="supplies"
          :usedSupplies="usedSupplies"
          :disabled="disabled"
          :disabled-delete="model.rates.length <= 1"
          :static-mode="staticMode"
          @delete="handleDelete"
          @change="handleChange"
          @add="handleAdd"
        />
      </template>
    </q-table>

    <div v-if="!staticMode" class="text-left q-pb-md q-pt-sm q-px-xs">
      <div
        class="clickable rounded q-px-sm q-py-md text-caption"
        :class="$q.dark.isActive ? 'bg-dark' : 'bg-grey-3 text-grey-7'"
        :disable="isAddDisabled"
        @click="handleAdd"
      >
        <span class="border-bottom" style="border-bottom-color: #757575 !important;">
          <q-icon name="add" />

          <span>
            {{ $t('Add a New Supply') }}...
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import RateModifierServicesTableRow from './RateModifierServicesTableRow.vue'

export default {
  name: 'RateModifierServicesTable',
  emits: ['change', 'delete', 'rateChange'],
  components: {
    RateModifierServicesTableRow
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    portal: {
      type: Boolean,
      default () {
        return false
      }
    },
    noCodeField: {
      type: Boolean,
      default () {
        return false
      }
    },
    code: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    rate: {
      type: Object,
      default () {
        return {}
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          period: "month",
          periodCount: 1,
          virtual: false,
          rates: []
        }
      }
    }
  },
  data () {
    return {
      periods: [
        { id: 'unit', label: this.$t('Unit') },
      ],
      supplies: [],
      virtual: this.rate.hasVirtual ? this.rate.hasVirtual: false,
      usedSupplies: [],
      types: this.model.types || [],
      typesOptions: [
        {
          name: 'Retail',
          code: 'Orderadmin\\Products\\Entity\\Order'
        },
        {
          name: 'Wholesale',
          code: 'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder'
        },
        {
          name: 'Shipment',
          code: "Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest"
        },
        {
          name: 'Purchase Order',
          code: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance'
        },
        {
          name: 'Return',
          code: 'Orderadmin\\Products\\Entity\\Order\\ReturnOrder'
        },
        {
          name: 'Disposal',
          code: 'Orderadmin\\Products\\Entity\\Order\\Disposal'
        },
        {
          name: 'Warehouse',
          code: 'Orderadmin\\Storage\\Entity\\Warehouse'
        }
      ],
      columns: [
        {
          label: this.$t('Code'),
          name: 'code',
          align: 'center',
          classes: this.code ? '' : 'd-none',
          headerClasses: this.code ? '' : 'd-none'
        },
        {
          label: this.$t('Min Count'),
          name: 'min',
          align: 'center'
        },
        {
          label: this.$t('Max Count'),
          name: 'max',
          align: 'center'
        },
        {
          label: this.$t('Rate'),
          name: 'rate',
          align: 'center'
        },
        {
          label: '',
          name: 'actions',
          align: 'right',
          classes: this.staticMode
            ? 'd-none'
            : ''
        }
      ]
    }
  },
  computed: {
    isAddDisabled () {
        this.model.rates
        if(this.disabled) {
            return true
        }
        if (this.model.rates && this.model.rates.length > 0) {
            if (this.model.rates[this.model.rates.length - 1].code) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    },
    schema2 () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'select',
                label: this.$t('Period'),
                value: this.model.period,
                customLabel (row) {
                  return row && typeof row === 'object'
                      ? row.label
                      : row
                },
                wrapperStyleClasses: 'col-12 q-pa-xs',
                options: this.periods,
                onChange: (code) => {
                  this.emitChange({
                    ...this.model,
                    period: code.id
                  })
                }
              },
            ]
          }
        ]
      }
    },
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'tag',
                label: this.$t('Type'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                value: this.types,
                options: this.typesOptions,
                field: 'types',
                customTag: (item) => {
                  if (item && typeof item === 'object') {
                    return item.name
                  } else {
                    const test = this.typesOptions.filter(e=>e.code === item)
                    if ( test.length > 0 ) {
                      return test[0].name
                    }
                  }
                },
                customListItem: (row) => {
                  return this.$t(row.name)
                },
                onRemove: (val) => {
                  this.types = this.types.filter(x => x !== val)
                  this.emitChange({
                    ...this.model,
                    types: this.types
                  })
                },
                onAdd: (value) => {
                  if (Array.isArray(value)) {
                    return
                  }

                  let isExist = false

                  this.types = this.types.filter(val => {
                    if (val === value.code) {
                      isExist = true
                      return false
                    }

                    return true
                  })

                  if (isExist) {
                    return
                  }

                  this.types = [...this.types, value.code]
                  this.emitChange({
                    ...this.model,
                    types: this.types
                  })
                }
              }
            ]
          }
        ]
      }
    }
  },
  beforeMount() {
      let query = {
          per_page: 2500,
      }

      query['order-by'] = [
          {type: 'field', field: 'id', direction: 'desc'}
      ]
      query.filter = [{type: 'eq', field: 'state', value: 'active'}]

      return this.$service.supply.getAll(query)
          .then(supplies => {
              this.supplies = supplies.items
          })
  },
    mounted() {
      if(this.model.rates){
          this.usedSupplies = this.model.rates.reduce((acc, el) =>{
              if(!acc.includes(el.code)) {
                 acc.push(el.code)
              }
              return acc
          },[])
      }
    },
    methods: {
    handleVirtual () {
        this.virtual = !this.virtual
        this.emitRateChange({
            ...this.rate,
            hasVirtual: this.virtual
        })
    },
    handleRemove () {
      this.$emit('delete', this.model)
    },
    emitRateChange (rate) {
      this.$emit('rateChange', rate)
    },
    emitChange (model) {
      this.$emit('change', model)
    },
    getPrevRow (index) {
      const rates = (this.model.rates || [])
      return rates[index - 1] || null
    },
    getNextRow (index) {
      const rates = (this.model.rates || [])
      return rates[index + 1] || null
    },
    handleClick(code) {
      this.emitChange({
        ...this.model,
        period: code.id
      })
    },
    handleInput (code) {
      if(!this.model.period){
        this.emitChange({
          ...this.model,
          period: 'unit',
          code
        })
      } else {
        this.emitChange({
          ...this.model,
          code
        })
      }
    },
    handleDelete (rowIndex) {
      const update = {
        ...this.model,
        rates: this.model.rates.filter((x, i) => i !== rowIndex)
      }

      this.emitChange(update)
    },
    handleAdd (withSupplies, thisRow) {
      if (withSupplies.code) {

        const newRow = { code: withSupplies.code, rate: 0}

        const update = {
            ...this.model,
            rates: [
                ...this.model.rates
            ]
        }
        const nextRow = thisRow + 1
        update.rates.splice(nextRow, 0 , newRow)

        this.emitChange(update)
        return
      }
      if (this.isAddDisabled) {
          return;
      }
      if (this.disabled) {
        return
      }


      const newRow = { rate: 0}

      const update = {
        ...this.model,
        rates: [
          ...this.model.rates,
          newRow
        ]
      }

      this.emitChange(update)
    },
    handleChange ({ rowIndex, row }) {
      const update = {
        ...this.model,
        rates: [
          ...this.model.rates.slice(0, rowIndex),
          row,
          ...this.model.rates.slice(rowIndex + 1)
        ]
      }

      this.emitChange(update)
    }
  }
}
</script>
