<template>
  <div>
    <div class="row justify-end">
      <q-btn
        size="sm"
        icon="close"
        no-caps
        flat
        unelevated
        @click="handleClose"
      />
    </div>

    <div class="row justify-center">
      <h5 class="q-my-none">
        {{ title }}
      </h5>
    </div>

    <legal-entity-form />

    <div class="row justify-end border-top q-pt-sm">
      <div class="q-mr-sm">
        <q-btn
          color="dark"
          text-color="white"
          size="sm"
          :label="$t('Close')"
          no-caps
          unelevated
          @click="handleClose"
        />
      </div>

      <q-btn
        color="light-blue-9"
        text-color="white"
        size="sm"
        :label="$t('Save')"
        no-caps
        unelevated
        @click="save"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import LegalEntityForm from './LegalEntityForm.vue'

export default {
  name: 'LegalEntity',
  emits: ['submit', 'close'],
  components: {
    LegalEntityForm
  },
  props: {
    owner: {
      type: Object,
      default () {
        return null
      }
    },
    id: {
      type: Number,
      default () {
        return null
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'legalEntity',
      'isSupervisior',
      'isAdministrator'
    ]),
    title () {
      return this.id
        ? this.$t('Legal Entity') + ': ' + this.id
        : this.$t('New Legal Entity')
    }
  },
  mounted () {
    if (this.id && !this.legalEntity) {
      this.loadLegalEntity(this.id)
    }

    if (!this.id) {
      this.setNewLegalEntity()

      if (!this.isSupervisior && !this.isAdministrator) {
        const owner = this.owner || JSON.parse(localStorage.getItem('userData'))
        this.updateLegalEntity({
          _embedded: {
            ...this.legalEntity._embedded,
            owner
          }
        })
      }
    }
  },
  unmounted () {
    this.setLegalEntity(null)
  },
  methods: {
    ...mapActions([
      'saveLegalEntity',
      'loadLegalEntity'
    ]),
    ...mapMutations([
      'setLegalEntity',
      'setNewLegalEntity',
      'updateLegalEntity',
      'addErrorNotification'
    ]),
    save () {
      this.saveLegalEntity()
        .then(entity => {
          this.$emit('submit', entity)
          this.handleClose()
        })
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>
