<template>
  <rate-modifier-storage-charge-row
      :static-mode="staticMode"
      :model="model"
      :type="((model.conditions || []).find(x => x.field === 'type') || {}).value"
      :keywords="keywords"
      :disabled="disabled"
      @change="handleChange"
      @delete="$emit('delete', model)"
  />
</template>

<script>
// Components
import RateModifierStorageChargeRow from './RateModifierStorageChargeRow.vue'

export default {
  name: 'RateModifierServices',
  emits: ['change'],
  components: {
    RateModifierStorageChargeRow
  },
  props: {
    keywords: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          period: 'month',
          periodCount: 1,
          rates: null,
          dimensions: {
            x: null,
            y: null,
            z: null
          },
          sections: {
            included: [],
            excluded: []
          }
        }
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    handleChange (model) {
      this.$emit('change', model)
    }
  }
}
</script>
