<template>
  <div>
    <action-header
      :is-loading="!accountRate"
      :actions-model="headerModel"
      :page="page"
    />

    <rate-modifiers
      v-if="accountRate"
      ref="rateModifiers"
      :disabled="isSaveLoading"
      :account-rate="accountRate"
      @change="handleModifierChange"
    />

    <div v-if="isModal" class="text-center q-pa-xs border-top">
      <q-btn
        color="light-blue-9"
        text-color="white"
        size="sm"
        :label="$t('Save')"
        class="q-mr-sm"
        no-caps
        unelevated
        @click="save"
      />

      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        :label="$t('Back')"
        no-caps
        unelevated
        @click="handleBack"
      />
    </div>

    <sticky-bottom-header
      v-else
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />

    <account-rates-modal ref="accountRatesModal" @submit="handleAccountRateCopy" />

    <accounts-modal ref="accountsModal" />

    <account-rate-modal ref="accountRateModal" @submit="handleSubmit" />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader.vue'
import RateModifiers from '../../components/account/RateModifiers.vue'
import AccountsModal from '../../components/modals/AccountsModal.vue'
import AccountRatesModal from '../../components/modals/AccountRatesModal.vue'
import AccountRateModal from '../../components/modals/AccountRateModal.vue'

// Utils
import { convertEmbedded, difference } from '../../helpers/request-helpers'

export default {
  name: 'AccountRate',
  components: {
    ActionHeader,
    RateModifiers,
    AccountsModal,
    AccountRatesModal,
    AccountRateModal
  },
  props: {
    isModal: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      tab: 'acceptance'
    }
  },
  computed: {
    ...mapGetters([
      'accountRate',
      'currentUser'
    ]),
    page () {
      return {
        id: this.accountRate && this.accountRate.id,
        name: this.accountRate && this.accountRate.id
          ? this.$t('Billing')
          : this.$t('New billing')
      }
    },
    headerModel () {
      if (!this.accountRate) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-3 mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-white',
              value: this.accountRate && this.accountRate.id
                ? this.$t('Billing') + ':' + this.accountRate.id
                : this.$t('New billing')
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-sm-4 row justify-center',
          options: [
            {
              id: 'accounts',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              icon: 'exposure',
              disabled: !this.accountRate || !this.accountRate.id,
              label: this.$t('Customers'),
              onClick: () => {
                this.$refs.accountsModal.open(this.accountRate, `${this.$t('The assigned accounts')}`)
              }
            }
          ]
        },
        {
          section: 'service',
          className: 'col-12 col-sm-4 row justify-end',
          options: [
            {
              id: 'accountRate',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              icon: 'settings',
              disabled: !this.accountRate,
              label: this.$t('Settings'),
              onClick: () => {
                this.$refs.accountRateModal.open({...this.accountRate ,currency:this.accountRate._embedded.currency})
              }
            },
            {
              id: 'portal',
              type: 'portal',
              name: 'billing-header'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.id) {
      if (!this.accountRate) {
        return this.loadAccountRate(this.$route.params.id)
      }

      return
    }

    this.setNewAccountRate()
  },
  unmounted () {
    this.setAccountRate(null)
  },
  methods: {
    ...mapActions([
      'loadAccountRate',
      'saveAccountRate'
    ]),
    ...mapMutations([
      'addErrorNotification',
      'setNewAccountRate',
      'setAccountRate',
      'updateAccountRateValues',
      'updateAccountRate',
      'upsertAccountRate',
      'updateAccountRateEmbedded'
    ]),
    handleSubmit (item) {
      this.upsertAccountRate(item)
      this.setAccountRate(item)
    },
    handleModifierChange () {
      this.hasChange = true
    },
    handleAccountRateCopy (accountRate) {
      const update = {
        ...accountRate,
        id: undefined,
        created: undefined,
        rateModifiers: undefined,
        updated: undefined,
        _links: undefined,
        accounts: undefined,
        _embedded: {
          ...(accountRate._embedded || {}),
          owner: this.currentUser
        }
      }

      this.setAccountRate(update)
      this.$refs.rateModifiers.copyFrom(accountRate)
      this.hasChange = true
    },
    save () {
      const items = this.$refs.rateModifiers.getValues()
      const originalItems = this.$refs.rateModifiers.getOriginalValues()
      const tabs = this.$refs.rateModifiers.tabs
      const tabsWithError = items.reduce((acc, item) => {
        if ((!item.comment || !item.name) && !acc.find(x => x.code === item.code)) {
          const tab = tabs.find(x => x.code === item.code)

          if (tab) {
            acc.push(tab)
          }
        }

        return acc
      }, [])

      if (tabsWithError.length > 0) {
        tabsWithError.forEach(tab => {
          this.addErrorNotification(`You have missing fee name or comment in ${tab.name.toLowerCase()} tab!`)
        })

        return
      }

      this.isSaveLoading = true

      let rate = null

      return this.saveAccountRate()
        .then(item => {
          rate = item
          this.upsertAccountRate(item)
          return this.saveRateModifiers(item, items, originalItems)
        })
        .then(() => {
          this.$refs.rateModifiers.reset()
          this.hasChange = false

          if (this.isModal) {
            this.$emit('submit', rate)
          } else if (!this.$route.params.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    saveRateModifiers (rate, items, orItems, results = []) {
      if (items.length <= 0) {
        return Promise.resolve(results)
      }

      const item = items[0]
      const orItem = orItems.find(x => x.id === item.id)

      // Create new rate modifier
      if (!orItem) {
        const data = convertEmbedded(item)
        data.id = undefined
        data.rate = rate.id

        return this.$service.rateModifier.save(data)
          .then(item => {
            results.push(item)
            return this.saveRateModifiers(rate, items.slice(1), orItems, results)
          })
      }

      // Save changes if their is changes
      const data = convertEmbedded(difference(item, orItem))

      if (data.settings) {
        data.settings = item.settings
      }

      if (Object.keys(data).length <= 0) {
        results.push(item)
        return this.saveRateModifiers(rate, items.slice(1), orItems, results)
      }

      return this.$service.rateModifier.save(data, item.id)
        .then(item => {
          results.push(item)
          return this.saveRateModifiers(rate, items.slice(1), orItems, results)
        })
    },
    handleDiscard () {
      if (this.isModal) {
        this.$emit('close')
        return
      }

      this.$router.go()
    },
    handleBack () {
      if (this.isModal) {
        this.$emit('close')
        return
      }

      this.$router.back()
    }
  }
}
</script>
