<template>
  <q-dialog v-model="isOpen" class="modal-md">
    <q-card>
      <q-card-section class="row q-pb-none">
        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="hide"
        />
      </q-card-section>

      <q-card-section
        v-for="(section, i) in data"
        :key="section.id"
        :class="i === 0 ? 'q-pt-none' : ''"
      >
        <h6 class="q-mt-none q-mb-md text-center text-weight-bold">
          {{ $t(section.title) }}
        </h6>

        <div
          v-for="description in section.descriptions"
          :key="description.id"
          class="q-mb-sm text-subtitle1"
        >
          <span class="text-weight-bold">
            {{ $t(description.value) }}
          </span>

          -

          <span>
            {{ $t(description.text) }}.
          </span>

          <div
            v-if="description.caption"
            class="q-mt-sm"
          >
            {{ $t(description.caption) }}
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'InfoDataModal',
  data () {
    return {
      isOpen: false,
      data: []
    }
  },
  methods: {
    open (data) {
      this.data = data
      this.isOpen = true
    },
    hide () {
      this.isOpenFilter = false
      this.isOpen = false
    }
  }
}
</script>
