<template>
  <div class="relative">
    <q-btn
      style="position: absolute; top: 0; right: 0;"
      text-color="dark"
      size="sm"
      icon="close"
      no-caps
      flat
      unelevated
      @click="handleClose"
    />

    <h5 class="q-mt-sm q-mb-md text-center">
      {{ $t('New Account') }}
    </h5>

    <form-builder ref="formBuilder" :schema="schema" />

    <div class="row justify-center items-center q-pt-md">
      <q-btn
        color="light-blue-9"
        text-color="white"
        :label="$t('Save')"
        class="q-mr-sm"
        no-caps
        unelevated
        @click="save"
      />

      <q-btn
        color="dark"
        text-color="white"
        :label="$t('Close')"
        no-caps
        unelevated
        @click="handleClose"
      />
    </div>

    <q-dialog v-model="billingModal" class="modal-exl">
      <q-card>
        <account-rate
          is-modal
          @submit="handleBilling"
          @close="handleBillingClose"
        />
      </q-card>
    </q-dialog>

    <q-dialog v-model="legalEntityModal">
      <q-card>
        <q-card-section>
          <legal-entity
            :owner="newAccount._embedded.owner"
            @submit="handleSubmit"
            @close="handleLegalEntityModal"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Helpers
import { convertEmbedded } from '../../helpers/request-helpers'

// Components
import AccountRate from '../../pages/settings/AccountRate.vue'
import LegalEntity from '../legal-entities/LegalEntity.vue'

export default {
  name: 'Account',
  emits: ['save', 'close'],
  components: {
    LegalEntity,
    AccountRate
  },
  props: {
    checkForAccounts: {
      type: Array,
      default () {
        return null
      }
    },
    types: {
      type: Array,
      default () {
        return [
          { id: 'deposit', name: 'Deposit' },
          { id: 'payment', name: 'Payment' }
        ]
      }
    },
    type: {
      type: String,
      default () {
        return null
      }
    },
    rate: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      legalEntityModal: false,
      billingModal: false,
      legalEntityKey: 'contractor',
      isDisabledTypeField: false,
      currentTypes: [...this.types],
      error: '',
      legalEntityError: '',
      newAccount: {
        name: '',
        type: '',
        creditLimit: 0,
        _embedded: {
          contractor: null,
          customer: null,
          rate: null
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'currentUser',
      'accounts',
      'isSupervisior',
      'isClient',
      'isEmployee',
      'isAdministrator'
    ]),
    schema () {
      return {
        groups: [
          {
            id: 'form',
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Billing profile'),
                value: this.newAccount._embedded.rate,
                disabled: !!this.rate,
                required: true,
                wrapperStyleClasses: 'col-12 q-pa-xs',
                hint: this.$t('Select the billing for the account or create a new one.'),
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search
                  }

                  return this.$service.accountRate.getAll(query)
                },
                onChange: (rate) => {
                  this.newAccount._embedded.rate = rate
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    if: this.newAccount.type === 'deposit',
                    id: 'add',
                    icon: 'add',
                    color: 'teal',
                    size: 'lg',
                    onClick: () => {
                      this.billingModal = true
                    }
                  }
                ]
              },
              {
                if: this.isSupervisior || this.isAdministrator,
                type: 'multiselect',
                label: this.$t('Owner'),
                value: this.newAccount._embedded.owner,
                required: true,
                hint: this.$t('Select the user for the account.'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      {
                        type: 'innerjoin',
                        field: 'roles',
                        parentAlias: 'u',
                        alias: 'r'
                      },
                      {
                        field: 'id',
                        alias: 'r',
                        type: 'eq',
                        value: 8
                      }
                    ]
                  }

                  return this.$service.user.getAll(query)
                },
                onChange: (owner) => {
                  if (this.checkForAccounts && owner) {
                    this.setOwner(owner)
                  } else {
                    this.newAccount = {
                      ...this.newAccount,
                      _embedded: {
                        ...this.newAccount._embedded,
                        owner
                      }
                    }
                  }

                  this.resetCustomer()

                  if (owner) {
                    this.loadCustomer(owner.id)
                      .then(customer => {
                        this.newAccount = {
                          ...this.newAccount,
                          _embedded: {
                            ...this.newAccount._embedded,
                            customer
                          }
                        }
                      })
                  } else {
                    this.newAccount = {
                      ...this.newAccount,
                      _embedded: {
                        ...this.newAccount._embedded,
                        customer: null
                      }
                    }
                  }
                }
              },
              {
                type: 'input',
                inputType: 'text',
                value: this.newAccount.name,
                label: this.$t('Name'),
                hint: this.$t('Enter the name of the account.'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                required: true,
                onChange: name => {
                  this.newAccount.name = name
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                hint: this.$t('Select the deposit type for invoicing and charging your clients.'),
                value: this.newAccount.type,
                customLabel: (row) => {
                  return row && typeof row === 'object'
                    ? this.$t(row.name)
                    : row
                },
                wrapperStyleClasses: 'col-12 q-pa-xs',
                options: this.currentTypes,
                required: true,
                disabled: !!this.type || this.currentTypes.length <= 1,
                onChange: (type) => {
                  this.newAccount.type = type.id
                }
              },
              {
                ref: 'contractor',
                type: 'multiselect',
                label: this.$t('Contractor'),
                value: this.newAccount._embedded.contractor,
                required: true,
                hint: this.$t('Select your legal entity.'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.legalEntity.getAll(query)
                    .then(data => {
                      if (!search && data.items.length <= 0) {
                        this.addErrorNotification('Please create a Legal entity for this user!')
                      }

                      return data
                    })
                },
                onChange: (contractor) => {
                  this.newAccount._embedded.contractor = contractor
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'add',
                    icon: 'add',
                    color: 'teal',
                    size: 'lg',
                    onClick: () => {
                      this.legalEntityKey = 'contractor'
                      this.legalEntityModal = true
                    }
                  }
                ]
              },
              {
                ref: 'customer',
                type: 'multiselect',
                label: this.$t('Customer'),
                value: this.newAccount._embedded.customer,
                required: true,
                error: this.legalEntityError,
                hint: this.$t('Select the legal entity of your client or create a new one.'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (this.newAccount._embedded.owner) {
                    query.filter.push({ type: 'eq', field: 'owner', value: this.newAccount._embedded.owner.id })
                  }

                  return this.$service.legalEntity.getAll(query)
                    .then(data => {
                      if (!search && data.items.length <= 0) {
                        this.addErrorNotification('Please create a Legal entity for this user!')
                      }

                      return data
                    })
                },
                onChange: (customer) => {
                  this.newAccount._embedded.customer = customer
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'add',
                    icon: 'add',
                    color: 'teal',
                    size: 'lg',
                    onClick: () => {
                      this.legalEntityKey = 'customer'
                      this.legalEntityModal = true
                    }
                  }
                ]
              },
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.newAccount.type = this.type || null
    this.newAccount._embedded.rate = this.rate || null

    if (!this.type && this.types.length === 1) {
      this.newAccount.type = this.types[0].id
    }

    if (this.isClient || this.isEmployee) {
      this.setOwner(this.currentUser)
      this.loadCustomer(this.currentUser.id)
        .then(customer => {
          this.newAccount._embedded.customer = customer
        })
    }
  },
  methods: {
    ...mapActions([
      'loadCurrencies',
      'loadLegalEntities',
      'loadAccountRates'
    ]),
    ...mapMutations([
      'addErrorNotification',
      'startLoading',
      'stopLoading'
    ]),
    handleBilling (rate) {
      this.newAccount._embedded.rate = rate
    },
    handleBillingClose () {
      this.billingModal = false
    },
    getFormRef () {
      return this.$refs.formBuilder.$refs.form[0].$refs
    },
    getCustomerRef () {
      return this.getFormRef().customer[0].$refs.customer
    },
    getContractorRef () {
      return this.getFormRef().contractor[0].$refs.contractor
    },
    resetCustomer () {
      this.getCustomerRef().reset()
    },
    resetContractor () {
      this.getContractorRef().reset()
    },
    loadCustomer (owner) {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'owner', value: owner }
        ]
      }

      return this.$service.legalEntity.getAll(query)
        .then(({ items, totalItems }) => {
          if (totalItems <= 0) {
            this.legalEntityError = 'Please create a Legal entity for this user!'
            this.addErrorNotification(this.legalEntityError)
          } else if (totalItems === 1) {
            this.legalEntityError = ''
            return items[0]
          }

          return null
        })
    },
    setOwner (owner) {
      return this.checkAccounts(owner)
        .then(types => {
          this.error = ''
          this.currentTypes = this.types.filter(x => types.includes(x.id))

          if (this.currentTypes.length === 1) {
            this.newAccount.type = this.currentTypes[0].id
          }

          this.newAccount = {
            ...this.newAccount,
            _embedded: {
              ...this.newAccount._embedded,
              owner
            }
          }
        })
        .catch(err => {
          this.error = err.message
          this.addErrorNotification(err)
        })
    },
    checkAccounts (owner) {
      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'owner', value: owner.id },
          ...this.checkForAccounts
        ]
      }

      this.startLoading()

      return this.$service.accounts.getAll(query)
        .then(({ items }) => {
          const typeFilter = this.checkForAccounts.find(x => x.field === 'type') || { type: 'in', values: ['deposit', 'payment'] }
          const types = (typeFilter.type === 'in' && typeFilter.values) || [typeFilter.value]

          const reducedTypes = types.reduce((acc, type) => {
            const hasNot = !items.find(x => x.type === type)

            if (hasNot && !acc.find(x => x === type)) {
              acc.push(type)
            }

            return acc
          }, [])

          if (reducedTypes.length <= 0) {
            return Promise.reject(new Error('Selected user already has required accounts!'))
          }

          return reducedTypes
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    handleLegalEntityModal () {
      this.legalEntityModal = false
    },
    handleSubmit (legalEntity) {
      this.newAccount._embedded[this.legalEntityKey] = legalEntity
      this.legalEntityError = ''
    },
    save () {
      if (this.error) {
        this.addErrorNotification(this.error)
        return
      }

      const error = this.$utils.validate.account(this.newAccount)

      if (error) {
        this.addErrorNotification(error)
        return
      }

      const data = convertEmbedded(this.newAccount)

      if (this.rate) {
        data.rate = this.rate.id
      }

      return this.$service.accounts.save(data)
        .then(account => {
          this.$emit('save', account)
          this.handleClose()
        })
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>
