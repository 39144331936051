<template>
  <rate-modifier-services-charge-row
      :static-mode="staticMode"
      :model="model"
      :rate="rate"
      :type="((model.conditions || []).find(x => x.field === 'type') || {}).value"
      :keywords="keywords"
      :disabled="disabled"
      @change="handleChange"
      @rateChange="handleRateChange"
      @delete="$emit('delete', model)"
  />
</template>

<script>
// Components
import RateModifierServicesChargeRow from './RateModifierServicesChargeRow.vue'

export default {
  name: 'RateModifierServices',
  emits: ['change', 'rateChange'],
  components: {
    RateModifierServicesChargeRow
  },
  props: {
    keywords: {
      type: Array,
      default () {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    rate: {
      type: Object,
      default () {
        return {}
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: null,
          period: 'month',
          periodCount: 1,
          rates: null
        }
      }
    }
  },
  data () {
    return {}
  },
  methods: {
    handleRateChange (rate) {
      this.$emit('rateChange', rate)
    },
    handleChange (model) {
      this.$emit('change', model)
    }
  }
}
</script>
