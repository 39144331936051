<template>
  <div>
    <rate-modifier-services-table
      :static-mode="staticMode"
      :model="model"
      :rate="rate"
      :disabled="disabled"
      code
      portal
      @rateChange="handleRateChange"
      @change="handleChange"
    />
  </div>
</template>

<script>
// Components
import RateModifierServicesTable from './RateModifierServicesTable.vue'

export default {
  name: 'RateModifierServicesChargeRow',
  emits: ['change', 'delete', 'rateChange'],
  components: {
    RateModifierServicesTable
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    keywords: {
      type: Array,
      default () {
        return []
      }
    },
    type: {
      type: String,
      default () {
        return ''
      }
    },
    removeBtn: {
      type: Boolean,
      default () {
        return false
      }
    },
    staticMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    rate: {
      type: Object,
      default () {
        return {}
      }
    },
    model: {
      type: Object,
      default () {
        return {
          code: 0,
          name: '',
          comment: '',
          conditions: [],
          period: "month",
          periodCount: 1,
          rates: []
        }
      }
    }
  },
  data () {
    return {
      names: {
        'products': 'Products',
        'items': 'Items',
        'palletized': 'Pallets',
        'batch': 'Batch & EXP'
      }
    }
  },
  methods: {
    handleRateChange (rate) {
        this.$emit('rateChange', rate)
    },
    handleInsert (option, editor) {
      const data = {
        content: option.name,
        props: {
          style: 'background: orange;',
          'data-id': option.id
        }
      }

      editor.plugins.span.insert(data)
    },
    handleSurcharges (surcharges) {
      this.handleChange({ ...this.model, surcharges })
    },
    handleDelete (model) {
      this.$emit('delete', model)
    },
    handleChange (model) {
      this.$emit('change', model)
    }
  }
}
</script>
